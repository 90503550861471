import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { ValidationService } from 'app/services/validation.service';
import { eLabToasterService } from 'app/services/toaster.service';
import { UserService } from 'app/services/user.service';
import { ProcedureService } from 'app/services/procedure.service';
import { Preference } from 'app/model/Preference';
import { AngularDualListBoxModule, DualListComponent } from 'angular-dual-listbox';
import { Langue } from 'app/model/Langue';
import { LangageService } from 'app/services/langage.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

// Page des préférences utilisateur
@Component({
  selector: 'settings',
  templateUrl: './settings.html'
})
export class Settings implements OnInit{

	public form:FormGroup;
	public password:AbstractControl;
	public confirmPassword:AbstractControl;
	public oldPassword:AbstractControl;

	sourcePreferences: any[];
	selectedPreferences: any[];
	display: any;
	key: any;
	format: any = {direction: DualListComponent.LTR, draggable: true};

	langues: Langue[];
	selectedLangue: Langue;


  	constructor(
  		private formBuilder: FormBuilder,
  		private toaster:eLabToasterService, 
  		private userService: UserService, 
  		private procedureService: ProcedureService, 
  		private langageService: LangageService, 
  		private translate: TranslateService,
  		private router: Router) {

  		// init fomulaire mot de passe avec les validateurs
  		this.form = this.formBuilder.group({	
  		  oldPassword: ['', Validators.compose([Validators.required, ValidationService.passwordValidator])],      
	      password: ['', Validators.compose([Validators.required, ValidationService.passwordValidator])],
	      confirmPassword: ['', Validators.compose([Validators.required, ValidationService.passwordValidator])]
	    });

		this.oldPassword = this.form.controls['oldPassword'];
	    this.password = this.form.controls['password'];
	    this.confirmPassword = this.form.controls['confirmPassword'];	    

  	}

  	ngOnInit() {

  		this.translate.use(localStorage.getItem('langage'));  		
  		// vérification de la connexion utilisateur
		this.userService.refresh().subscribe(
             ok => {                
                if(ok.access_token == null) {
                    localStorage.removeItem('user');
                    this.toaster.pop('warning', this.translate.instant('message.redirection'), this.translate.instant('error.authentication'));
                    this.router.navigate(['/login']);
                }
             }
        );

  		this.getPreferences();
  		// recupération de l'ensemble des langues
  		this.langageService.allLangage().subscribe(
  			langues => 
  				{
  					this.langues = langues;  
  					this.selectedLangue = this.langues.find(function(e){
  						return e.code.toLowerCase() === localStorage.getItem('langage').toLowerCase();
  					});					 		
  				},
			error => {            
      			this.toaster.pop('error', this.translate.instant('message.error'), this.translate.instant('error.server'));
			});  		
	}

	// lance le changement du mot de passe utilisateur
	public changePassword():void {

		if(this.password.value === this.confirmPassword.value){

			let passwordChanger = {oldPassword: this.oldPassword.value, newPassword: this.password.value}

			this.userService.changePassword(passwordChanger)
		    .subscribe(
		    	ok => {	             
		      		this.toaster.pop('success', this.translate.instant('message.settings'), this.translate.instant('message.passwordChangeSuccess'));
		      		this.userService.refresh().subscribe(
				      ok => {                
	                    if(ok.access_token == null) {
	                        localStorage.removeItem('user');
	                        this.toaster.pop('warning', 'Redirection', this.translate.instant('error.authentication'));
	                        this.router.navigate(['/login']);
	                    }
	                 }
			      );	      	   
		    	},
		    	error => {	
		    		if(error.status == 200){
		    			this.toaster.pop('success', this.translate.instant('message.settings'), this.translate.instant('message.passwordChangeSuccess'));
			      		this.userService.refresh().subscribe(
					      ok => {                
		                    if(ok.access_token == null) {
		                        localStorage.removeItem('user');
		                        this.toaster.pop('warning', 'Redirection', this.translate.instant('error.authentication'));
		                        this.router.navigate(['/login']);
		                    }
		                 }
				      );
		    		} else {
		    			this.toaster.pop('error', this.translate.instant('message.error'), this.translate.instant('message.passwordChangeError'));
		    		}         
		      		
		    	}
		    );

		} else {

			this.toaster.pop('error', this.translate.instant('message.error'), this.translate.instant('message.passwordIdentical'));

		}

	    
	}

	// formatte l'affichage des colonnes
	getNomPreference(item: Preference) {
		if(item.field){
  			return this.translate.instant('column.' + item.field);
  		} else {
  			return item.libelle
  		}
	}

	getId(item: Preference) {
  		return item.id;
	}

	// lance la sauvegarde des préférences
	public savePreferences() {
		this.procedureService.savePreferences(this.selectedPreferences).subscribe(
  					ok => {            
		      			this.toaster.pop('success', this.translate.instant('message.settings'), this.translate.instant('message.preferenceSaveSuccess'));
		      			this.userService.refresh().subscribe(
					      ok => {                
		                    if(ok.access_token == null) {
		                        localStorage.removeItem('user');
		                        this.toaster.pop('warning', this.translate.instant('message.redirection'), this.translate.instant('error.authentication'));
		                        this.router.navigate(['/login']);
		                    }
		                 }
				      );      	   
  					},
  					error => {           
		      			this.toaster.pop('error', this.translate.instant('message.error'), this.translate.instant('message.preferenceSaveError'));
		      			this.userService.refresh().subscribe(
			                 ok => {                
			                    if(ok.access_token == null) {
			                        localStorage.removeItem('user');
			                        this.toaster.pop('warning', this.translate.instant('message.redirection'), this.translate.instant('error.authentication'));
			                        this.router.navigate(['/login']);
			                    }
			                 }
			            );
  					});
	}

	// lance la sauvegarde des préférences de langue
	public saveLangue() {
		this.langageService.saveLangage(this.selectedLangue.code).subscribe(
  					user => {             
		      			this.toaster.pop('success', this.translate.instant('message.settings'), this.translate.instant('message.langageSaveSuccess'));
		      			this.translate.use(user.langue.toLowerCase());
		      			this.langageService.setLangage(user.langue.toLowerCase());
		      			localStorage.setItem('langage', user.langue.toLowerCase());
		      			this.getPreferences();    	  
		      			this.userService.refresh().subscribe(
					     ok => {                
		                    if(ok.access_token == null) {
		                        localStorage.removeItem('user');
		                        this.toaster.pop('warning', this.translate.instant('message.redirection'), this.translate.instant('error.authentication'));
		                        this.router.navigate(['/login']);
		                    }
		                 }
				      );
  					},
  					error => {         
		      			this.toaster.pop('error', this.translate.instant('message.error'), this.translate.instant('message.langageSaveError'));
		      			this.userService.refresh().subscribe(
			                 ok => {                
			                    if(ok.access_token == null) {
			                        localStorage.removeItem('user');
			                        this.toaster.pop('warning', this.translate.instant('message.redirection'), this.translate.instant('error.authentication'));
			                        this.router.navigate(['/login']);
			                    }
			                 }
			            );
  					});
	}

	// initialisation des 2 colonnes de la dual list
	getPreferences() {

		this.procedureService.getAllPreferences().subscribe(
  			preferences => {  				 			
  				this.sourcePreferences = preferences; 
  				this.procedureService.getUserPreferences().subscribe(
  					selectedPreferences => {
  						this.selectedPreferences = selectedPreferences; 
  						this.display = this.getNomPreference.bind(this);
	    				this.key = 'id';	
	    				this.format.add = this.translate.instant('dualList.add');
						this.format.remove = this.translate.instant('dualList.remove');
						this.format.all = this.translate.instant('dualList.all');
						this.format.none = this.translate.instant('dualList.none');  	    					
  					});
  				this.userService.refresh().subscribe(
			      ok => {                
                    if(ok.access_token == null) {
                        localStorage.removeItem('user');
                        this.toaster.pop('warning', this.translate.instant('message.redirection'), this.translate.instant('error.authentication'));
                        this.router.navigate(['/login']);
                    }
                 }
		      );
  			},
  			error => {
  				this.toaster.pop('error', this.translate.instant('message.error'), this.translate.instant('error.server'));
  				this.userService.refresh().subscribe(
			                 ok => {                
			                    if(ok.access_token == null) {
			                        localStorage.removeItem('user');
			                        this.toaster.pop('warning', this.translate.instant('message.redirection'), this.translate.instant('error.authentication'));
			                        this.router.navigate(['/login']);
			                    }
			                 }
			            );
  			});		
	}

}
