import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as AppUtils from 'app/app.utils';
import { Observable } from 'rxjs';

@Injectable()
export class VersionService {

  constructor(
      private http: HttpClient 
  ) { }

  // récupération de la version de l'application
  getVersion() {
    return this.http.get(AppUtils.BACKEND_API_VERSION, { withCredentials: false, responseType: 'text'});
  }
 

}
