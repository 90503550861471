import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as AppUtils from 'app/app.utils';
import { Langue } from 'app/model/Langue';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { User } from 'app/model/User';

@Injectable()
export class LangageService {

  langage: string;

  constructor(
  	private http: HttpClient,
    private translate: TranslateService
  ) { 
    this.langage = this.translate.currentLang || navigator.language.substring(0,2); 
  }

  getLangage(): string {
    return this.langage;
  }

  setLangage(langue : string) {
    this.langage = langue;
  }

  //récupère la langue de l'utilisateur
  getUserLangage() {
    return this.http.get(AppUtils.BACKEND_API_LANG, {withCredentials: true, responseType: 'text'});
  }

  // sauvegarde la langue d'un utilisateur
  saveLangage(langage: string): Observable<User> {
    return this.http.post<User>(AppUtils.BACKEND_API_SAVE_LANGUE, langage, {withCredentials: true});
  }

  // récupération de l'ensemble des langues
  allLangage(): Observable<Langue[]> {
    return this.http.get<Langue[]>(AppUtils.BACKEND_API_ALL_LANGUE, {withCredentials: true});
  }

}
