import { EchantillonClient } from './EchantillonClient';

export class DemandeClientSimple {

  id: string;
  numCommande: string;
  dossier: string;
  client: string;
  dateEnvoi: string;
  statut: string;
  typeAnalyse: string;
  proprietaire: string;
  dateModification: string;
  commentaire: string;
  titre: string;
  echantillons: EchantillonClient[];

  constructor() {  
  	
  }
    
}