import { Component, OnInit } from '@angular/core';
import {ColumnApi, GridApi, GridOptions} from "ag-grid/main";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from 'app/services/user.service';
import { LangageService } from 'app/services/langage.service';
import { BlocHtml } from 'app/model/BlocHtml';
import { TranslateService } from '@ngx-translate/core';
import { eLabToasterService } from 'app/services/toaster.service';
import { Router } from '@angular/router';
import { map, filter, catchError, mergeMap } from 'rxjs/operators';


@Component({
  selector: 'accueil',
  templateUrl: './accueil.html'
})
export class Accueil implements OnInit{

	blocs: BlocHtml[];

  	constructor(private userService: UserService, private langageService: LangageService, private translate: TranslateService, private toaster:eLabToasterService, private router: Router) {


  	}

  	ngOnInit() {
  		this.translate.use(localStorage.getItem('langage'));
  		this.getBlocs();		
	}

	getBlocs() {

		this.userService.getAllBloc(localStorage.getItem('langage').toUpperCase()).subscribe(
			blocs =>
			{ this.blocs = blocs; }
		);
		this.userService.refresh().subscribe(
			ok => {                
				if(ok.access_token == null) {
					localStorage.removeItem('user');
					this.toaster.pop('warning', this.translate.instant('message.redirection'), this.translate.instant('error.authentication'));
					this.router.navigate(['/login']);
				}
			 }
		);
		
	}
}
