import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute} from '@angular/router';
import { DemandesService } from'app/services/demandes.service';
import { UserService } from'app/services/user.service';
import * as XLSX from 'xlsx';
import { HotTableComponent } from 'ng2-handsontable';
import { Tier } from 'app/model/Tier';
import * as moment from 'moment';
import { EchantillonClient } from 'app/model/EchantillonClient';
import { User } from 'app/model/User';
import { CommentaireModal } from'app/modal/commentaire/commentaireModal.component';
import { TitreModal } from'app/modal/titre/titreModal.component';
import { HelpModal } from'app/modal/help/helpModal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DemandeClientSimple } from 'app/model/DemandeClientSimple';
import { eLabToasterService } from 'app/services/toaster.service';
import cleanDeep from 'clean-deep';



@Component({
  selector: 'echantillons',
  templateUrl: './echantillons.html',
  styleUrls: ['./echantillons.css']
})
export class EchantillonsComponent implements OnInit{

  etat: string[];
  critere: string[];
  typeAnalyse: string[];
  services: string[];
  conditionnement: string[];
  rechercheSupp: string[];
  yesNoList: string[] = ['Oui', 'Non'];

  tiers: Tier[];

  options: any;
  data: EchantillonClient[] = [];
  colHeaders: string[];
  columns: any[] = [];

  statut: string;
  isLabo: boolean = false; 

  id: string;

  user: User;

  loading: boolean = false;

  @ViewChild('hot', { static: true }) hotTableComponent: HotTableComponent;

	constructor(private route: ActivatedRoute, private translate: TranslateService, private router: Router, private demandesService: DemandesService, private userService: UserService, private modalService: NgbModal, private toaster: eLabToasterService) {}

  ngOnInit() {

    this.id = this.route.snapshot.paramMap.get('id');

    this.isLabo = localStorage.getItem('user').search('ROLE_LABO') !== -1;

    this.user = JSON.parse(localStorage.getItem('user'));

    moment.locale(localStorage.getItem('langage'));

    $("#drop").on("dragover", function(event) {
        event.preventDefault();  
        event.stopPropagation();
    });

    $("#drop").on("dragleave", function(event) {
        event.preventDefault();  
        event.stopPropagation();
    });

    $('#drop').on("drop", this.handleDrop.bind(this));

    $('#xlf').on('change', this.handleFile.bind(this));

    this.demandesService.getEtatLangue(localStorage.getItem('langage')).subscribe(
      etat => { 
        this.etat = etat.map(e => e.code); 
        this.demandesService.getCritereLangue(localStorage.getItem('langage')).subscribe(
          critere => { 

            this.critere = critere.map(e => e.code);

            this.demandesService.getTypeAnalyseLangue(localStorage.getItem('langage')).subscribe(
              typeAnalyse => { 

                this.typeAnalyse = typeAnalyse.map(e => e.code);   

                this.demandesService.getServicesLangue(localStorage.getItem('langage')).subscribe(
                  services => { 

                    this.services = services.map(e => e.code); 

                    this.demandesService.getConditionnementLangue(localStorage.getItem('langage')).subscribe(
                      conditionnement => { 

                        this.conditionnement = conditionnement.map(e => e.code);   

                        this.demandesService.getRechercheSuppLangue(localStorage.getItem('langage')).subscribe(
                          rechercheSupp => { 

                            this.rechercheSupp = rechercheSupp.map(e => e.code);  

                            this.userService.getTiersByUser().subscribe( tiers => {
                             
                              this.tiers = tiers;

                              if(this.id) {
                                this.demandesService.getStatutDemande(this.id).subscribe(
                                  statut => {

                                    this.statut = statut;
                                    this.options = {
                                      minRows: 10,
                                      contextMenu: ((this.statut == 'attente' && !this.isLabo) || this.statut == 'validee' || (this.statut == 'refusee' && this.isLabo)) ? 
                                                   false :
                                                   {items: {'row_above' : {name: this.translate.instant('echantillons.row_above')}, 
                                                            'row_below' : {name: this.translate.instant('echantillons.row_below')}, 
                                                            'remove_row' : {name: this.translate.instant('echantillons.remove_row')}}},
                                      preventOverflow: 'horizontal',
                                      afterChange: this.afterChange.bind(this),
                                      afterCreateRow: this.afterCreateRow.bind(this)
                                    };
                                    this.constructColumn();
                                  }, 
                                  error =>
                                  {
                                    this.toaster.pop('error', this.translate.instant('message.error'), this.translate.instant('message.echantillonError'));
                                  });
                              } else {
                                this.statut = 'brouillon';
                                this.options = {
                                  minRows: 10,
                                  contextMenu:  ((this.statut == 'attente' && !this.isLabo) || this.statut == 'validee' || (this.statut == 'refusee' && this.isLabo)) ? 
                                                false :
                                                {items: {'row_above' : {name: this.translate.instant('echantillons.row_above')}, 
                                                         'row_below' : {name: this.translate.instant('echantillons.row_below')}, 
                                                         'remove_row' : {name: this.translate.instant('echantillons.remove_row')}}},
                                  preventOverflow: 'horizontal',
                                  afterChange: this.afterChange.bind(this),
                                  afterCreateRow: this.afterCreateRow.bind(this)
                                };
                                this.constructColumn();
                              }

                            }, 
                            error =>
                            {
                              this.toaster.pop('error', this.translate.instant('message.error'), this.translate.instant('message.echantillonError'));
                            });                       

                          }, 
                          error =>
                          {
                            this.toaster.pop('error', this.translate.instant('message.error'), this.translate.instant('message.echantillonError'));
                          });     
                      }, 
                      error =>
                      {
                        this.toaster.pop('error', this.translate.instant('message.error'), this.translate.instant('message.echantillonError'));
                      });         
                  }, 
                  error =>
                  {
                    this.toaster.pop('error', this.translate.instant('message.error'), this.translate.instant('message.echantillonError'));
                  });     
              }, 
              error =>
              {
                this.toaster.pop('error', this.translate.instant('message.error'), this.translate.instant('message.echantillonError'));
              });         
          }, 
          error =>
          {
            this.toaster.pop('error', this.translate.instant('message.error'), this.translate.instant('message.echantillonError'));
          });

        this.userService.refresh().subscribe(
            ok => {                
                if(ok.access_token == null) {
                    localStorage.removeItem('user');
                    this.toaster.pop('warning', this.translate.instant('message.redirection'), this.translate.instant('error.authentication'));
                    this.router.navigate(['/login']);
                }
             }
          );
        
      }, 
      error =>
      {
        this.toaster.pop('error', this.translate.instant('message.error'), this.translate.instant('message.echantillonError'));
      });    
    

  }

  validatorService(value, callback) {
    callback(true);
  }

  constructColumn() {
    this.demandesService.getColonnes(localStorage.getItem('langage').toUpperCase()).subscribe(
      colonnes => {
        this.columns = [];
        colonnes.forEach(
          e => {
            if(e.field === 'etat' ) {

              this.columns.push({type: 'dropdown',data: e.field, title: e.traduction, source: this.etat, readOnly: ((this.statut == 'attente' && !this.isLabo) || this.statut == 'validee' || (this.statut == 'refusee' && this.isLabo))}); 

            } else if (e.field === 'codeCritere') {

              this.columns.push({type: 'dropdown',data: e.field, title: e.traduction, source: this.critere, readOnly: ((this.statut == 'attente' && !this.isLabo) || this.statut == 'validee' || (this.statut == 'refusee' && this.isLabo))}); 

            } else if (e.field === 'rechercheSupp1' || e.field === 'rechercheSupp2' || e.field === 'rechercheSupp3') {

              this.columns.push({type: 'dropdown',data: e.field, title: e.traduction, source: this.rechercheSupp, readOnly: ((this.statut == 'attente' && !this.isLabo) || this.statut == 'validee' || (this.statut == 'refusee' && this.isLabo))}); 

            } else if (e.field === 'investigation') {

              this.columns.push({type: 'dropdown',data: e.field, title: e.traduction, source: this.yesNoList, readOnly: ((this.statut == 'attente' && !this.isLabo) || this.statut == 'validee' || (this.statut == 'refusee' && this.isLabo))}); 

            } else if (e.field === 'pathogene') {

              this.columns.push({type: 'dropdown',data: e.field, title: e.traduction, source: this.yesNoList, readOnly: ((this.statut == 'attente' && !this.isLabo) || this.statut == 'validee' || (this.statut == 'refusee' && this.isLabo))}); 

            } else if (e.field === 'typeAnalyse') {

              this.columns.push({type: 'dropdown',data: e.field, title: e.traduction, source: this.typeAnalyse, readOnly: ((this.statut == 'attente' && !this.isLabo) || this.statut == 'validee' || (this.statut == 'refusee' && this.isLabo))}); 

            } else if (e.field === 'service') {

              this.columns.push({type: 'dropdown',data: e.field, title: e.traduction, validator: this.validatorService, source: this.services, readOnly: ((this.statut == 'attente' && !this.isLabo) || this.statut == 'validee' || (this.statut == 'refusee' && this.isLabo))}); 

            } else if (e.field === 'conditionnement') {

              this.columns.push({type: 'dropdown',data: e.field, title: e.traduction, source: this.conditionnement, readOnly: ((this.statut == 'attente' && !this.isLabo) || this.statut == 'validee' || (this.statut == 'refusee' && this.isLabo))}); 

            } else if (e.field === 'centre') {

              if(this.tiers.length>1) {
                this.columns.push({type: 'dropdown',data: e.field, title: e.traduction, source: this.tiers.map(e => e.libelle).sort(), readOnly: ((this.statut == 'attente' && !this.isLabo) || this.statut == 'validee' || (this.statut == 'refusee' && this.isLabo))}); 
              } else {
                this.columns.push({renderer: 'text',data: e.field, title: e.traduction, readOnly: true});  
              }                                

            } else if(e.field === 'dateReception' || e.field === 'visa' || e.field === 'tempReception' || e.field === 'dateAnalyse' || e.field === 'prefixe') {

              if(this.isLabo) {
                this.columns.push({renderer: 'text',data: e.field, title: e.traduction, readOnly: (this.statut == 'validee' || this.statut == 'refusee')});  
              }

            } else if(e.field === 'conforme') {

              if(this.isLabo) {
                this.columns.push({type: 'dropdown',data: e.field, title: e.traduction, source: ['Oui', 'Non', 'Imprenable'], readOnly: (this.statut == 'validee' || this.statut == 'refusee')});  
              }

            } else {

              this.columns.push({renderer: 'text',data: e.field, title: e.traduction, readOnly: ((this.statut == 'attente' && !this.isLabo) || this.statut == 'validee' || (this.statut == 'refusee' && this.isLabo))});  
    
            }
            
          });

          if(this.id) {
            this.demandesService.getEchantillonByDemande(this.id).subscribe(
              echantillons => {
                let echantillonsTmp = echantillons;
                if(this.user && this.isLabo && this.statut == 'attente'){
                  echantillonsTmp.forEach(e => e.visa = this.user.idUser ? this.user.idUser : "");
                }

                echantillonsTmp.forEach(e => {
                  e.dateReception = e.dateReception ? moment(e.dateReception, 'L', 'fr').locale(localStorage.getItem('langage')).format('L') : "";
                  e.dateAnalyse = e.dateAnalyse ? moment(e.dateAnalyse, 'L', 'fr').locale(localStorage.getItem('langage')).format('L') : "";
                  e.dateEnvoi = e.dateEnvoi ? moment(e.dateEnvoi, 'L', 'fr').locale(localStorage.getItem('langage')).format('L') : "";
                  e.datePrelevement = e.datePrelevement ? moment(e.datePrelevement, 'L', 'fr').locale(localStorage.getItem('langage')).format('L') : "";
                  e.dateFabrication = e.dateFabrication ? moment(e.dateFabrication, 'L', 'fr').locale(localStorage.getItem('langage')).format('L') : "";
                  e.dlc = e.dlc ? moment(e.dlc, 'L', 'fr').locale(localStorage.getItem('langage')).format('L') : "";
                });
                
                this.data = echantillonsTmp.length == 0 ? [] : echantillonsTmp; 
                this.userService.refresh().subscribe(
                  ok => {                
                      if(ok.access_token == null) {
                          localStorage.removeItem('user');
                          this.toaster.pop('warning', this.translate.instant('message.redirection'), this.translate.instant('error.authentication'));
                          this.router.navigate(['/login']);
                      }
                   }
                ); 
              }, 
              error =>
              {
                this.toaster.pop('error', this.translate.instant('message.error'), this.translate.instant('message.echantillonError'));
                this.userService.refresh().subscribe(
                       ok => {                
                          if(ok.access_token == null) {
                              localStorage.removeItem('user');
                              this.toaster.pop('warning', this.translate.instant('message.redirection'), this.translate.instant('error.authentication'));
                              this.router.navigate(['/login']);
                          }
                       }
                  );
              });
              
          }
    }, 
    error =>
    {
      this.toaster.pop('error', this.translate.instant('message.error'), this.translate.instant('message.echantillonError'));
      this.userService.refresh().subscribe(
             ok => {                
                if(ok.access_token == null) {
                    localStorage.removeItem('user');
                    this.toaster.pop('warning', this.translate.instant('message.redirection'), this.translate.instant('error.authentication'));
                    this.router.navigate(['/login']);
                }
             }
        );
    });
  }

  isTableValid() {

    let selectedCentre;
    let selectedAnalyse;
    let isFirstCentreSelected = true;
    let isFirstTypeSelected = true;

    let size = this.hotTableComponent.getHandsontableInstance().getSourceData().length;

    let hotTable = this.hotTableComponent.getHandsontableInstance();

    for (var i = 0; i < size; i++) {

      if(!this.hotTableComponent.getHandsontableInstance().isEmptyRow(i)){

        if(this.isLabo) {

          if(!hotTable.getDataAtRowProp(i, 'dateReception')) {
            (<any>hotTable.getCellMeta(i, hotTable.propToCol('dateReception'))).valid = false;
            hotTable.render();
            this.toaster.pop('error', this.translate.instant('message.error'), this.translate.instant('message.errorDateReception'));
            this.loading = false;
            return false;  
          } else {
            (<any>hotTable.getCellMeta(i, hotTable.propToCol('dateReception'))).valid = true;
            hotTable.render();
          }
          
          if(hotTable.getDataAtRowProp(i, 'dateReception') != "" && (!moment(hotTable.getDataAtRowProp(i, 'dateReception'), 'L', localStorage.getItem('langage')).isValid() || !moment(hotTable.getDataAtRowProp(i, 'dateReception'), 'DD/MM/YYYY').isAfter('01/01/2000', 'year'))) {
            (<any>hotTable.getCellMeta(i, hotTable.propToCol('dateReception'))).valid = false;
            hotTable.render();
            this.toaster.pop('error', this.translate.instant('message.error'), this.translate.instant('message.errorDateReception'));
            this.loading = false;
            return false;  
          } else {
            (<any>hotTable.getCellMeta(i, hotTable.propToCol('dateReception'))).valid = true;
            hotTable.render();
          }

          if(!hotTable.getDataAtRowProp(i, 'visa')) {
            (<any>hotTable.getCellMeta(i, hotTable.propToCol('visa'))).valid = false;
            hotTable.render();
            this.toaster.pop('error', this.translate.instant('message.error'), this.translate.instant('message.errorVisa'));
            this.loading = false;
            return false; 
          } else {
            (<any>hotTable.getCellMeta(i, hotTable.propToCol('visa'))).valid = true;
            hotTable.render();
          }

          if(hotTable.getDataAtRowProp(i, 'visa') != null && hotTable.getDataAtRowProp(i, 'visa').length > 15) {
            (<any>hotTable.getCellMeta(i, hotTable.propToCol('visa'))).valid = false;
            hotTable.render();
            this.toaster.pop('error', this.translate.instant('message.error'), this.translate.instant('message.errorVisa'));
            this.loading = false;
            return false; 
          } else {
            (<any>hotTable.getCellMeta(i, hotTable.propToCol('visa'))).valid = true;
            hotTable.render();
          }

          if((!hotTable.getDataAtRowProp(i, 'tempReception') || hotTable.getDataAtRowProp(i, 'tempReception') == '') && hotTable.getDataAtRowProp(i, 'conforme') != 'Imprenable') {
            (<any>hotTable.getCellMeta(i, hotTable.propToCol('tempReception'))).valid = false;
            hotTable.render();
            this.toaster.pop('error', this.translate.instant('message.error'), this.translate.instant('message.errorTempReception'));
            this.loading = false;
            return false; 
          } else {
            (<any>hotTable.getCellMeta(i, hotTable.propToCol('tempReception'))).valid = true;
            hotTable.render();
          }

          if(hotTable.getDataAtRowProp(i, 'tempReception') != null && hotTable.getDataAtRowProp(i, 'tempReception') != '' && hotTable.getDataAtRowProp(i, 'conforme') == 'Imprenable') {
            (<any>hotTable.getCellMeta(i, hotTable.propToCol('tempReception'))).valid = false;
            hotTable.render();
            this.toaster.pop('error', this.translate.instant('message.error'), this.translate.instant('message.errorTempReception'));
            this.loading = false;
            return false; 
          } else {
            (<any>hotTable.getCellMeta(i, hotTable.propToCol('tempReception'))).valid = true;
            hotTable.render();
          }

          if(hotTable.getDataAtRowProp(i, 'tempReception') != null && hotTable.getDataAtRowProp(i, 'tempReception').length > 10) {
            (<any>hotTable.getCellMeta(i, hotTable.propToCol('tempReception'))).valid = false;
            hotTable.render();
            this.toaster.pop('error', this.translate.instant('message.error'), this.translate.instant('message.errorTempReception'));
            this.loading = false;
            return false; 
          } else {
            (<any>hotTable.getCellMeta(i, hotTable.propToCol('tempReception'))).valid = true;
            hotTable.render();
          }

          if(!hotTable.getDataAtRowProp(i, 'conforme')) {
            (<any>hotTable.getCellMeta(i, hotTable.propToCol('conforme'))).valid = false;
            hotTable.render();
            this.toaster.pop('error', this.translate.instant('message.error'), this.translate.instant('message.errorConforme'));
            this.loading = false;
            return false; 
          } else {
            (<any>hotTable.getCellMeta(i, hotTable.propToCol('conforme'))).valid = true;
            hotTable.render();
          }

          if(hotTable.getDataAtRowProp(i, 'conforme') != null && ['Oui', 'Non', 'Imprenable'].indexOf(hotTable.getDataAtRowProp(i, 'conforme')) == -1) {
            (<any>hotTable.getCellMeta(i, hotTable.propToCol('conforme'))).valid = false;
            hotTable.render();
            this.toaster.pop('error', this.translate.instant('message.error'), this.translate.instant('message.errorConforme'));
            this.loading = false;
            return false; 
          } else {
            (<any>hotTable.getCellMeta(i, hotTable.propToCol('conforme'))).valid = true;
            hotTable.render();
          }

          if(!hotTable.getDataAtRowProp(i, 'dateAnalyse')) {
            (<any>hotTable.getCellMeta(i, hotTable.propToCol('dateAnalyse'))).valid = false;
            hotTable.render();
            this.toaster.pop('error', this.translate.instant('message.error'), this.translate.instant('message.errorDateAnalyse'));
            this.loading = false;
            return false;  
          } else {
            (<any>hotTable.getCellMeta(i, hotTable.propToCol('dateAnalyse'))).valid = true;
            hotTable.render();
          }

          if(hotTable.getDataAtRowProp(i, 'dateAnalyse') != "" && (!moment(hotTable.getDataAtRowProp(i, 'dateAnalyse'), 'L', localStorage.getItem('langage')).isValid() || !moment(hotTable.getDataAtRowProp(i, 'dateAnalyse'), 'DD/MM/YYYY').isAfter('01/01/2000', 'year'))) {
            (<any>hotTable.getCellMeta(i, hotTable.propToCol('dateAnalyse'))).valid = false;
            hotTable.render();
            this.toaster.pop('error', this.translate.instant('message.error'), this.translate.instant('message.errorDateAnalyse'));
            this.loading = false;
            return false; 
          } else {
            (<any>hotTable.getCellMeta(i, hotTable.propToCol('dateAnalyse'))).valid = true;
            hotTable.render();
          }

          if(hotTable.getDataAtRowProp(i, 'prefixe') != null && hotTable.getDataAtRowProp(i, 'prefixe').length > 26) {
            (<any>hotTable.getCellMeta(i, hotTable.propToCol('prefixe'))).valid = false;
            hotTable.render();
            this.toaster.pop('error', this.translate.instant('message.error'), this.translate.instant('message.errorPrefixe'));
            this.loading = false;
            return false; 
          } else {
            (<any>hotTable.getCellMeta(i, hotTable.propToCol('prefixe'))).valid = true;
            hotTable.render();
          }

          if(moment(hotTable.getDataAtRowProp(i, 'dateEnvoi'), 'L', localStorage.getItem('langage')).isAfter(moment(hotTable.getDataAtRowProp(i, 'dateReception'), 'L', localStorage.getItem('langage')))){
            (<any>hotTable.getCellMeta(i, hotTable.propToCol('dateReception'))).valid = false;
            hotTable.render();
            this.toaster.pop('error', this.translate.instant('message.error'), this.translate.instant('message.errorDateEnvoiDateReception'));
            this.loading = false;
            return false; 
          }

          if(moment(hotTable.getDataAtRowProp(i, 'dateEnvoi'), 'L', localStorage.getItem('langage')).isAfter(moment(hotTable.getDataAtRowProp(i, 'dateAnalyse'), 'L', localStorage.getItem('langage')))){
            (<any>hotTable.getCellMeta(i, hotTable.propToCol('dateAnalyse'))).valid = false;
            hotTable.render();
            this.toaster.pop('error', this.translate.instant('message.error'), this.translate.instant('message.errorDateEnvoiDateAnalyse'));
            this.loading = false;
            return false; 
          }

          if(moment(hotTable.getDataAtRowProp(i, 'dateReception'), 'L', localStorage.getItem('langage')).isAfter(moment(hotTable.getDataAtRowProp(i, 'dateAnalyse'), 'L', localStorage.getItem('langage')))){
            (<any>hotTable.getCellMeta(i, hotTable.propToCol('dateReception'))).valid = false;
            hotTable.render();
            this.toaster.pop('error', this.translate.instant('message.error'), this.translate.instant('message.errorDateReceptionDateAnalyse'));
            this.loading = false;
            return false; 
          }

         }

        if(hotTable.getDataAtRowProp(i, 'centre')) {
          if(this.tiers.length>1 && this.tiers.map(e => e.libelle).indexOf(hotTable.getDataAtRowProp(i, 'centre')) == -1){
            (<any>hotTable.getCellMeta(i, hotTable.propToCol('centre'))).valid = false;
            hotTable.render();
            this.toaster.pop('error', this.translate.instant('message.error'), this.translate.instant('message.errorCentre'));
            this.loading = false;
            return false; 
          } else {
            if(isFirstCentreSelected) {
              selectedCentre = hotTable.getDataAtRowProp(i, 'centre');
              isFirstCentreSelected = false;
            } else {
              if(hotTable.getDataAtRowProp(i, 'centre') != selectedCentre) {
                (<any>hotTable.getCellMeta(i, hotTable.propToCol('centre'))).valid = false;
                hotTable.render();
                this.toaster.pop('error', this.translate.instant('message.error'), this.translate.instant('message.errorAllCentre'));
                this.loading = false;
                return false;
              } else {
                (<any>hotTable.getCellMeta(i, hotTable.propToCol('centre'))).valid = true;
                hotTable.render();
              }
            }            
          }
        } else {
          (<any>hotTable.getCellMeta(i, hotTable.propToCol('centre'))).valid = true;
          hotTable.render();
        }

        if(!hotTable.getDataAtRowProp(i, 'centre')) {   
          (<any>hotTable.getCellMeta(i, hotTable.propToCol('centre'))).valid = false;
          hotTable.render();
          this.toaster.pop('error', this.translate.instant('message.error'), this.translate.instant('message.errorCentre'));
          this.loading = false;
          return false;       
        } else {
          (<any>hotTable.getCellMeta(i, hotTable.propToCol('centre'))).valid = true;
          hotTable.render();
        }

        if(hotTable.getDataAtRowProp(i, 'etat')) {
          if(this.etat.length>1 && this.etat.indexOf(hotTable.getDataAtRowProp(i, 'etat')) == -1){
            (<any>hotTable.getCellMeta(i, hotTable.propToCol('etat'))).valid = false;
            hotTable.render();
            this.toaster.pop('error', this.translate.instant('message.error'), this.translate.instant('message.errorEtat'));
            this.loading = false;
            return false; 
          } else {
            (<any>hotTable.getCellMeta(i, hotTable.propToCol('etat'))).valid = true;
            hotTable.render();
          }
        } else {
          (<any>hotTable.getCellMeta(i, hotTable.propToCol('etat'))).valid = true;
          hotTable.render();
        }

        if(hotTable.getDataAtRowProp(i, 'numEchantillon') != null && hotTable.getDataAtRowProp(i, 'numEchantillon').length > 60) {
          (<any>hotTable.getCellMeta(i, hotTable.propToCol('numEchantillon'))).valid = false;
          hotTable.render();
          this.toaster.pop('error', this.translate.instant('message.error'), this.translate.instant('message.errorNumEchantillon'));
          this.loading = false;
          return false; 
        } else {
          (<any>hotTable.getCellMeta(i, hotTable.propToCol('numEchantillon'))).valid = true;
          hotTable.render();
        }

        if(!hotTable.getDataAtRowProp(i, 'designation')) {
          (<any>hotTable.getCellMeta(i, hotTable.propToCol('designation'))).valid = false;
          hotTable.render();
          this.toaster.pop('error', this.translate.instant('message.error'), this.translate.instant('message.errorDesignation'));
          this.loading = false;
          return false; 
        } else {
          (<any>hotTable.getCellMeta(i, hotTable.propToCol('designation'))).valid = true;
          hotTable.render();
        }

        if(hotTable.getDataAtRowProp(i, 'designation') != null && hotTable.getDataAtRowProp(i, 'designation').length > 200) {
          (<any>hotTable.getCellMeta(i, hotTable.propToCol('designation'))).valid = false;
          hotTable.render();
          this.toaster.pop('error', this.translate.instant('message.error'), this.translate.instant('message.errorDesignation'));
          this.loading = false;
          return false; 
        } else {
          (<any>hotTable.getCellMeta(i, hotTable.propToCol('designation'))).valid = true;
          hotTable.render();
        }        

        if(hotTable.getDataAtRowProp(i, 'codeCritere')) {
          if(this.critere.length>1 && this.critere.indexOf(hotTable.getDataAtRowProp(i, 'codeCritere')) == -1){
            (<any>hotTable.getCellMeta(i, hotTable.propToCol('codeCritere'))).valid = false;
            hotTable.render();
            this.toaster.pop('error', this.translate.instant('message.error'), this.translate.instant('message.errorCritere'));
            this.loading = false;
            return false; 
            } else {
            (<any>hotTable.getCellMeta(i, hotTable.propToCol('codeCritere'))).valid = true;
            hotTable.render();
          }
        } else {
          (<any>hotTable.getCellMeta(i, hotTable.propToCol('codeCritere'))).valid = true;
          hotTable.render();
        }

        if(hotTable.getDataAtRowProp(i, 'rechercheSupp1')) {
          if(this.rechercheSupp.length>1 && this.rechercheSupp.indexOf(hotTable.getDataAtRowProp(i, 'rechercheSupp1')) == -1){
            (<any>hotTable.getCellMeta(i, hotTable.propToCol('rechercheSupp1'))).valid = false;
            hotTable.render();
            this.toaster.pop('error', this.translate.instant('message.error'), this.translate.instant('message.errorRechercheSupp'));
            this.loading = false;
            return false; 
          } else {
            (<any>hotTable.getCellMeta(i, hotTable.propToCol('rechercheSupp1'))).valid = true;
            hotTable.render();
          }
        } else {
          (<any>hotTable.getCellMeta(i, hotTable.propToCol('rechercheSupp1'))).valid = true;
          hotTable.render();
        }

        if(hotTable.getDataAtRowProp(i, 'seuil1')) {
          hotTable.setDataAtRowProp(i, 'seuil1', hotTable.getDataAtRowProp(i, 'seuil1').toString().replace(" ",""));
          hotTable.setDataAtRowProp(i, 'seuil1', hotTable.getDataAtRowProp(i, 'seuil1').toString().replace(",",""));
          if(!hotTable.getDataAtRowProp(i, 'seuil1').toString().match(/^\d+$/gm)){
            (<any>hotTable.getCellMeta(i, hotTable.propToCol('seuil1'))).valid = false;
            hotTable.render();
            this.toaster.pop('error', this.translate.instant('message.error'), this.translate.instant('message.errorSeuil'));
            this.loading = false;
            return false; 
          } else {
            (<any>hotTable.getCellMeta(i, hotTable.propToCol('seuil1'))).valid = true;
            hotTable.render();
          }
        } else {
          (<any>hotTable.getCellMeta(i, hotTable.propToCol('seuil1'))).valid = true;
          hotTable.render();
        }

        if(hotTable.getDataAtRowProp(i, 'rechercheSupp2')) {
          if(this.rechercheSupp.length>1 && this.rechercheSupp.indexOf(hotTable.getDataAtRowProp(i, 'rechercheSupp2')) == -1){
            (<any>hotTable.getCellMeta(i, hotTable.propToCol('rechercheSupp2'))).valid = false;
            hotTable.render();
            this.toaster.pop('error', this.translate.instant('message.error'), this.translate.instant('message.errorRechercheSupp'));
            this.loading = false;
            return false; 
          } else {
            (<any>hotTable.getCellMeta(i, hotTable.propToCol('rechercheSupp2'))).valid = true;
            hotTable.render();
          }
        } else {
          (<any>hotTable.getCellMeta(i, hotTable.propToCol('rechercheSupp2'))).valid = true;
          hotTable.render();
        }

        if(hotTable.getDataAtRowProp(i, 'seuil2')) {
          hotTable.setDataAtRowProp(i, 'seuil2', hotTable.getDataAtRowProp(i, 'seuil2').toString().replace(" ",""));
          hotTable.setDataAtRowProp(i, 'seuil2', hotTable.getDataAtRowProp(i, 'seuil2').toString().replace(",",""));
          if(!hotTable.getDataAtRowProp(i, 'seuil2').toString().match(/^\d+$/gm)){
            (<any>hotTable.getCellMeta(i, hotTable.propToCol('seuil2'))).valid = false;
            hotTable.render();
            this.toaster.pop('error', this.translate.instant('message.error'), this.translate.instant('message.errorSeuil'));
            this.loading = false;
            return false; 
          } else {
            (<any>hotTable.getCellMeta(i, hotTable.propToCol('seuil2'))).valid = true;
            hotTable.render();
          }
        } else {
          (<any>hotTable.getCellMeta(i, hotTable.propToCol('seuil2'))).valid = true;
          hotTable.render();
        }

        if(hotTable.getDataAtRowProp(i, 'rechercheSupp3')) {
          if(this.rechercheSupp.length>1 && this.rechercheSupp.indexOf(hotTable.getDataAtRowProp(i, 'rechercheSupp3')) == -1){
            (<any>hotTable.getCellMeta(i, hotTable.propToCol('rechercheSupp3'))).valid = false;
            hotTable.render();
            this.toaster.pop('error', this.translate.instant('message.error'), this.translate.instant('message.errorRechercheSupp'));
            this.loading = false;
            return false; 
          } else {
            (<any>hotTable.getCellMeta(i, hotTable.propToCol('rechercheSupp3'))).valid = true;
            hotTable.render();
          }
        } else {
          (<any>hotTable.getCellMeta(i, hotTable.propToCol('rechercheSupp3'))).valid = true;
          hotTable.render();
        }

        if(hotTable.getDataAtRowProp(i, 'seuil3')) {
          hotTable.setDataAtRowProp(i, 'seuil3', hotTable.getDataAtRowProp(i, 'seuil3').toString().replace(" ",""));
          hotTable.setDataAtRowProp(i, 'seuil3', hotTable.getDataAtRowProp(i, 'seuil3').toString().replace(",",""));
          if(!hotTable.getDataAtRowProp(i, 'seuil3').toString().match(/^\d+$/gm)){
            (<any>hotTable.getCellMeta(i, hotTable.propToCol('seuil3'))).valid = false;
            hotTable.render();
            this.toaster.pop('error', this.translate.instant('message.error'), this.translate.instant('message.errorSeuil'));
            this.loading = false;
            return false; 
          } else {
            (<any>hotTable.getCellMeta(i, hotTable.propToCol('seuil3'))).valid = true;
            hotTable.render();
          }
        } else {
          (<any>hotTable.getCellMeta(i, hotTable.propToCol('seuil3'))).valid = true;
          hotTable.render();
        }

        if(hotTable.getDataAtRowProp(i, 'surface')) {
          if(!hotTable.getDataAtRowProp(i, 'surface').toString().match(/^\d+$/gm)){
            (<any>hotTable.getCellMeta(i, hotTable.propToCol('surface'))).valid = false;
            hotTable.render();
            this.toaster.pop('error', this.translate.instant('message.error'), this.translate.instant('message.errorSurface'));
            this.loading = false;
            return false; 
          } else {
            (<any>hotTable.getCellMeta(i, hotTable.propToCol('surface'))).valid = true;
            hotTable.render();
          }
        } else {
          (<any>hotTable.getCellMeta(i, hotTable.propToCol('surface'))).valid = true;
          hotTable.render();
        }

        if(hotTable.getDataAtRowProp(i, 'investigation')) {
          if(this.yesNoList.length>1 && this.yesNoList.indexOf(hotTable.getDataAtRowProp(i, 'investigation')) == -1){
            (<any>hotTable.getCellMeta(i, hotTable.propToCol('investigation'))).valid = false;
            hotTable.render();
            this.toaster.pop('error', this.translate.instant('message.error'), this.translate.instant('message.errorInvestigation'));
            this.loading = false;
            return false; 
          } else {
            (<any>hotTable.getCellMeta(i, hotTable.propToCol('investigation'))).valid = true;
            hotTable.render();
          }
        } else {
          (<any>hotTable.getCellMeta(i, hotTable.propToCol('investigation'))).valid = true;
          hotTable.render();
        }

        if(hotTable.getDataAtRowProp(i, 'pathogene')) {
          if(this.yesNoList.length>1 && this.yesNoList.indexOf(hotTable.getDataAtRowProp(i, 'pathogene')) == -1){
            (<any>hotTable.getCellMeta(i, hotTable.propToCol('pathogene'))).valid = false;
            hotTable.render();
            this.toaster.pop('error', this.translate.instant('message.error'), this.translate.instant('message.errorPathogene'));
            this.loading = false;
            return false; 
          } else {
            (<any>hotTable.getCellMeta(i, hotTable.propToCol('pathogene'))).valid = true;
            hotTable.render();
          }
        } else {
          (<any>hotTable.getCellMeta(i, hotTable.propToCol('pathogene'))).valid = true;
          hotTable.render();
        }

        if(hotTable.getDataAtRowProp(i, 'dateEnvoi') && hotTable.getDataAtRowProp(i, 'dateEnvoi') != "" && (!moment(hotTable.getDataAtRowProp(i, 'dateEnvoi'), 'L', localStorage.getItem('langage')).isValid() || !moment(hotTable.getDataAtRowProp(i, 'dateEnvoi'), 'DD/MM/YYYY').isAfter('01/01/2000', 'year'))) {
          (<any>hotTable.getCellMeta(i, hotTable.propToCol('dateEnvoi'))).valid = false;
          hotTable.render();
          this.toaster.pop('error', this.translate.instant('message.error'), this.translate.instant('message.errorDateEnvoi'));
          this.loading = false;
          return false; 
        } else if(!hotTable.getDataAtRowProp(i, 'dateEnvoi')){
          
            (<any>hotTable.getCellMeta(i, hotTable.propToCol('dateEnvoi'))).valid = false;
            hotTable.render();
            this.toaster.pop('error', this.translate.instant('message.error'), this.translate.instant('message.errorDateEnvoi'));
            this.loading = false;
            return false;
        }else {
            (<any>hotTable.getCellMeta(i, hotTable.propToCol('dateEnvoi'))).valid = true;
            hotTable.render();
        }

        if(hotTable.getDataAtRowProp(i, 'datePrelevement') != null && hotTable.getDataAtRowProp(i, 'datePrelevement') != "" && (!moment(hotTable.getDataAtRowProp(i, 'datePrelevement'), 'L', localStorage.getItem('langage')).isValid() || !moment(hotTable.getDataAtRowProp(i, 'datePrelevement'), 'DD/MM/YYYY').isAfter('01/01/2000', 'year'))) {
          (<any>hotTable.getCellMeta(i, hotTable.propToCol('datePrelevement'))).valid = false;
          hotTable.render();
          this.toaster.pop('error', this.translate.instant('message.error'), this.translate.instant('message.errorDatePrelevement'));
          this.loading = false;
          return false; 
        } else {
          (<any>hotTable.getCellMeta(i, hotTable.propToCol('datePrelevement'))).valid = true;
          hotTable.render();
        }

        if(hotTable.getDataAtRowProp(i, 'typeAnalyse')) {
          if(this.typeAnalyse.length>1 && this.typeAnalyse.indexOf(hotTable.getDataAtRowProp(i, 'typeAnalyse')) == -1){
            (<any>hotTable.getCellMeta(i, hotTable.propToCol('typeAnalyse'))).valid = false;
            hotTable.render();
            this.toaster.pop('error', this.translate.instant('message.error'), this.translate.instant('message.errorTypeAnalyse'));
            this.loading = false;
            return false; 
          } else {
            if(isFirstTypeSelected) {
              selectedAnalyse = hotTable.getDataAtRowProp(i, 'typeAnalyse');
              isFirstTypeSelected = false;
            } else {
              if(hotTable.getDataAtRowProp(i, 'typeAnalyse') != selectedAnalyse) {
                (<any>hotTable.getCellMeta(i, hotTable.propToCol('typeAnalyse'))).valid = false;
                hotTable.render();
                this.toaster.pop('error', this.translate.instant('message.error'), this.translate.instant('message.errorAllTypeAnalyse'));
                this.loading = false;
                return false; 
              } else {
                (<any>hotTable.getCellMeta(i, hotTable.propToCol('typeAnalyse'))).valid = true;
                hotTable.render();
              }
            }
          }
        } else {
          (<any>hotTable.getCellMeta(i, hotTable.propToCol('typeAnalyse'))).valid = true;
          hotTable.render();
        }

        if(!hotTable.getDataAtRowProp(i, 'typeAnalyse')) {        
          (<any>hotTable.getCellMeta(i, hotTable.propToCol('typeAnalyse'))).valid = false;
          hotTable.render();
          this.toaster.pop('error', this.translate.instant('message.error'), this.translate.instant('message.errorTypeAnalyse'));
          this.loading = false;
          return false;        
        } else {
          (<any>hotTable.getCellMeta(i, hotTable.propToCol('typeAnalyse'))).valid = true;
          hotTable.render();
        }

        if(hotTable.getDataAtRowProp(i, 'dateFabrication') != null && hotTable.getDataAtRowProp(i, 'dateFabrication') != "" && (!moment(hotTable.getDataAtRowProp(i, 'dateFabrication'), 'L', localStorage.getItem('langage')).isValid() || !moment(hotTable.getDataAtRowProp(i, 'dateFabrication'), 'DD/MM/YYYY').isAfter('01/01/2000', 'year'))) {
          (<any>hotTable.getCellMeta(i, hotTable.propToCol('dateFabrication'))).valid = false;
          hotTable.render();
          this.toaster.pop('error', this.translate.instant('message.error'), this.translate.instant('message.errorDateFabrication'));
          this.loading = false;
          return false; 
        } else if(hotTable.getDataAtRowProp(i, 'dateFabrication') != null && hotTable.getDataAtRowProp(i, 'dateFabrication') != "" && moment(hotTable.getDataAtRowProp(i, 'dateFabrication'), 'L', localStorage.getItem('langage')).isValid()
                   && hotTable.getDataAtRowProp(i, 'dlc') != null && hotTable.getDataAtRowProp(i, 'dlc') != "" && moment(hotTable.getDataAtRowProp(i, 'dlc'), 'L', localStorage.getItem('langage')).isValid()
                  && moment(hotTable.getDataAtRowProp(i, 'dateFabrication'), 'L', localStorage.getItem('langage')).isAfter(moment(hotTable.getDataAtRowProp(i, 'dlc'), 'L', localStorage.getItem('langage')))){
          (<any>hotTable.getCellMeta(i, hotTable.propToCol('dateFabrication'))).valid = false;
          (<any>hotTable.getCellMeta(i, hotTable.propToCol('dlc'))).valid = false;
          hotTable.render();
          this.toaster.pop('error', this.translate.instant('message.error'), this.translate.instant('message.errorDateFabricationDateDLC'));
          this.loading = false;
          return false; 
        }else {
          (<any>hotTable.getCellMeta(i, hotTable.propToCol('dateFabrication'))).valid = true;
          hotTable.render();
        }  

        if(hotTable.getDataAtRowProp(i, 'dlc') != null && hotTable.getDataAtRowProp(i, 'dlc') != "" && (!moment(hotTable.getDataAtRowProp(i, 'dlc'), 'L', localStorage.getItem('langage')).isValid() || !moment(hotTable.getDataAtRowProp(i, 'dlc'), 'DD/MM/YYYY').isAfter('01/01/2000', 'year'))) {
          (<any>hotTable.getCellMeta(i, hotTable.propToCol('dlc'))).valid = false;
          hotTable.render();
          this.toaster.pop('error', this.translate.instant('message.error'), this.translate.instant('message.errorDlc'));
          this.loading = false;
          return false;  
        } else {
          (<any>hotTable.getCellMeta(i, hotTable.propToCol('dlc'))).valid = true;
          hotTable.render();
        }  

        if(hotTable.getDataAtRowProp(i, 'dateFabricationTxt') != null && hotTable.getDataAtRowProp(i, 'dateFabricationTxt').length > 10) {
          (<any>hotTable.getCellMeta(i, hotTable.propToCol('dateFabricationTxt'))).valid = false;
          hotTable.render();
          this.toaster.pop('error', this.translate.instant('message.error'), this.translate.instant('message.errorDateFabricationTxt'));
          this.loading = false;
          return false; 
        } else {
          (<any>hotTable.getCellMeta(i, hotTable.propToCol('dateFabricationTxt'))).valid = true;
          hotTable.render();
        }

        if(hotTable.getDataAtRowProp(i, 'dlcTxt') != null && hotTable.getDataAtRowProp(i, 'dlcTxt').length > 10) {
          (<any>hotTable.getCellMeta(i, hotTable.propToCol('dlcTxt'))).valid = false;
          hotTable.render();
          this.toaster.pop('error', this.translate.instant('message.error'), this.translate.instant('message.errorDlcTxt'));
          this.loading = false;
          return false; 
        } else {
          (<any>hotTable.getCellMeta(i, hotTable.propToCol('dlcTxt'))).valid = true;
          hotTable.render();
        }  

        if(hotTable.getDataAtRowProp(i, 'lieu') != null && hotTable.getDataAtRowProp(i, 'lieu').length > 30) {
          (<any>hotTable.getCellMeta(i, hotTable.propToCol('lieu'))).valid = false;
          hotTable.render();
          this.toaster.pop('error', this.translate.instant('message.error'), this.translate.instant('message.errorLieu'));
          this.loading = false;
          return false; 
        } else {
          (<any>hotTable.getCellMeta(i, hotTable.propToCol('lieu'))).valid = true;
          hotTable.render();
        }  

        if(hotTable.getDataAtRowProp(i, 'service') != null && hotTable.getDataAtRowProp(i, 'service').length > 30) {
          (<any>hotTable.getCellMeta(i, hotTable.propToCol('service'))).valid = false;
          hotTable.render();
          this.toaster.pop('error', this.translate.instant('message.error'), this.translate.instant('message.errorService'));
          this.loading = false;
          return false; 
        } else {
          (<any>hotTable.getCellMeta(i, hotTable.propToCol('service'))).valid = true;
          hotTable.render();
        }  

        if(hotTable.getDataAtRowProp(i, 'conditionnement')) {
          if(this.conditionnement.length>1 && this.conditionnement.indexOf(hotTable.getDataAtRowProp(i, 'conditionnement')) == -1){
            (<any>hotTable.getCellMeta(i, hotTable.propToCol('conditionnement'))).valid = false;
            hotTable.render();
            this.toaster.pop('error', this.translate.instant('message.error'), this.translate.instant('message.errorConditionnement'));
            this.loading = false;
            return false; 
          } else {
            (<any>hotTable.getCellMeta(i, hotTable.propToCol('conditionnement'))).valid = true;
            hotTable.render();
          }
        } else {
          (<any>hotTable.getCellMeta(i, hotTable.propToCol('conditionnement'))).valid = true;
          hotTable.render();
        }

        if(hotTable.getDataAtRowProp(i, 'fournisseur') != null && hotTable.getDataAtRowProp(i, 'fournisseur').length > 50) {
          (<any>hotTable.getCellMeta(i, hotTable.propToCol('fournisseur'))).valid = false;
          hotTable.render();
          this.toaster.pop('error', this.translate.instant('message.error'), this.translate.instant('message.errorFournisseur'));
          this.loading = false;
          return false; 
        } else {
          (<any>hotTable.getCellMeta(i, hotTable.propToCol('fournisseur'))).valid = true;
          hotTable.render();
        }  

        if(hotTable.getDataAtRowProp(i, 'compagnie') != null && hotTable.getDataAtRowProp(i, 'compagnie').length > 50) {
          (<any>hotTable.getCellMeta(i, hotTable.propToCol('compagnie'))).valid = false;
          hotTable.render();
          this.toaster.pop('error', this.translate.instant('message.error'), this.translate.instant('message.errorCompagnie'));
          this.loading = false;
          return false; 
        } else {
          (<any>hotTable.getCellMeta(i, hotTable.propToCol('compagnie'))).valid = true;
          hotTable.render();
        }        

        if(hotTable.getDataAtRowProp(i, 'numLot') != null && hotTable.getDataAtRowProp(i, 'numLot').length > 40) {
          (<any>hotTable.getCellMeta(i, hotTable.propToCol('numLot'))).valid = false;
          hotTable.render();
          this.toaster.pop('error', this.translate.instant('message.error'), this.translate.instant('message.errorNumLot'));
          this.loading = false;
          return false; 
        } else {
          (<any>hotTable.getCellMeta(i, hotTable.propToCol('numLot'))).valid = true;
          hotTable.render();
        }           

        if(hotTable.getDataAtRowProp(i, 'numCEE') != null && hotTable.getDataAtRowProp(i, 'numCEE').length > 20) {
          (<any>hotTable.getCellMeta(i, hotTable.propToCol('numCEE'))).valid = false;
          hotTable.render();
          this.toaster.pop('error', this.translate.instant('message.error'), this.translate.instant('message.errorNumCee'));
          this.loading = false;
          return false; 
        } else {
          (<any>hotTable.getCellMeta(i, hotTable.propToCol('numCEE'))).valid = true;
          hotTable.render();
        }

        if(hotTable.getDataAtRowProp(i, 'codeMenu') != null && hotTable.getDataAtRowProp(i, 'codeMenu').length > 20) {
          (<any>hotTable.getCellMeta(i, hotTable.propToCol('codeMenu'))).valid = false;
          hotTable.render();
          this.toaster.pop('error', this.translate.instant('message.error'), this.translate.instant('message.errorCodeMenu'));
          this.loading = false;
          return false; 
        } else {
          (<any>hotTable.getCellMeta(i, hotTable.propToCol('codeMenu'))).valid = true;
          hotTable.render();
        }

        if(hotTable.getDataAtRowProp(i, 'codeElement') != null && hotTable.getDataAtRowProp(i, 'codeElement').length > 10) {
          (<any>hotTable.getCellMeta(i, hotTable.propToCol('codeElement'))).valid = false;
          hotTable.render();
          this.toaster.pop('error', this.translate.instant('message.error'), this.translate.instant('message.errorCodeElement'));
          this.loading = false;
          return false; 
        } else {
          (<any>hotTable.getCellMeta(i, hotTable.propToCol('codeElement'))).valid = true;
          hotTable.render();
        }

        if(hotTable.getDataAtRowProp(i, 'commentaire') != null && hotTable.getDataAtRowProp(i, 'commentaire').length > 2000) {
          (<any>hotTable.getCellMeta(i, hotTable.propToCol('commentaire'))).valid = false;
          hotTable.render();
          this.toaster.pop('error', this.translate.instant('message.error'), this.translate.instant('message.errorCommentaire'));
          this.loading = false;
          return false; 
        } else {
          (<any>hotTable.getCellMeta(i, hotTable.propToCol('commentaire'))).valid = true;
          hotTable.render();
        }

        if(hotTable.getDataAtRowProp(i, 'cdl') != null && hotTable.getDataAtRowProp(i, 'cdl').length > 50) {
          (<any>hotTable.getCellMeta(i, hotTable.propToCol('cdl'))).valid = false;
          hotTable.render();
          this.toaster.pop('error', this.translate.instant('message.error'), this.translate.instant('message.errorCdl'));
          this.loading = false;
          return false; 
        } else {
          (<any>hotTable.getCellMeta(i, hotTable.propToCol('cdl'))).valid = true;
          hotTable.render();
        }

        if(hotTable.getDataAtRowProp(i, 'escale') != null && hotTable.getDataAtRowProp(i, 'escale').length > 25) {
          (<any>hotTable.getCellMeta(i, hotTable.propToCol('escale'))).valid = false;
          hotTable.render();
          this.toaster.pop('error', this.translate.instant('message.error'), this.translate.instant('message.errorEscale'));
          this.loading = false;
          return false; 
        } else {
          (<any>hotTable.getCellMeta(i, hotTable.propToCol('escale'))).valid = true;
          hotTable.render();
        }

        if(hotTable.getDataAtRowProp(i, 'vol') != null && hotTable.getDataAtRowProp(i, 'vol').length > 8) {
          (<any>hotTable.getCellMeta(i, hotTable.propToCol('vol'))).valid = false;
          hotTable.render();
          this.toaster.pop('error', this.translate.instant('message.error'), this.translate.instant('message.errorVol'));
          this.loading = false;
          return false; 
        } else {
          (<any>hotTable.getCellMeta(i, hotTable.propToCol('vol'))).valid = true;
          hotTable.render();
        }

        if(hotTable.getDataAtRowProp(i, 'tempDateEV') != null && hotTable.getDataAtRowProp(i, 'tempDateEV').length > 120) {
          (<any>hotTable.getCellMeta(i, hotTable.propToCol('tempDateEV'))).valid = false;
          hotTable.render();
          this.toaster.pop('error', this.translate.instant('message.error'), this.translate.instant('message.errorTempDateEV'));
          this.loading = false;
          return false; 
        } else {
          (<any>hotTable.getCellMeta(i, hotTable.propToCol('tempDateEV'))).valid = true;
          hotTable.render();
        }

        if(hotTable.getDataAtRowProp(i, 'datePrelevement') != null && hotTable.getDataAtRowProp(i, 'datePrelevement') != "" && moment(hotTable.getDataAtRowProp(i, 'datePrelevement'), 'L', localStorage.getItem('langage')).isValid()
                && hotTable.getDataAtRowProp(i, 'dateEnvoi') != null && hotTable.getDataAtRowProp(i, 'dateEnvoi') != "" && moment(hotTable.getDataAtRowProp(i, 'dateEnvoi'), 'L', localStorage.getItem('langage')).isValid()
              && moment(hotTable.getDataAtRowProp(i, 'dateEnvoi'), 'L', localStorage.getItem('langage')).isBefore(moment(hotTable.getDataAtRowProp(i, 'datePrelevement'), 'L', localStorage.getItem('langage')))){
            (<any>hotTable.getCellMeta(i, hotTable.propToCol('dateEnvoi'))).valid = false;
            (<any>hotTable.getCellMeta(i, hotTable.propToCol('datePrelevement'))).valid = false;
            hotTable.render();
            this.toaster.pop('error', this.translate.instant('message.error'), this.translate.instant('message.errorDatePrelevementDateEnvoi'));
            this.loading = false;
            return false; 
        } 

      }

    }

    return true;

  }

  updateDemande() {
    
    if(this.isTableValid()){
      if(this.id){

        let demande = new DemandeClientSimple();
        demande.echantillons = this.prepareData();
        demande.id = this.id;

        this.demandesService.updateDemande(demande).subscribe(
          echantillons => {
            this.toaster.pop('success', this.translate.instant('message.settings'), this.translate.instant('message.echantillonUpdateSuccess'));
            
            this.back();
            this.userService.refresh().subscribe(
              ok => {                
                  if(ok.access_token == null) {
                      localStorage.removeItem('user');
                      this.toaster.pop('warning', this.translate.instant('message.redirection'), this.translate.instant('error.authentication'));
                      this.router.navigate(['/login']);
                  }
               }
            );
          }, 
          error =>
          {
            this.toaster.pop('error', this.translate.instant('message.error'), this.translate.instant('message.echantillonUpdateError'));
            this.userService.refresh().subscribe(
                   ok => {                
                      if(ok.access_token == null) {
                          localStorage.removeItem('user');
                          this.toaster.pop('warning', this.translate.instant('message.redirection'), this.translate.instant('error.authentication'));
                          this.router.navigate(['/login']);
                      }
                   }
              );
          });
      } else {
        const activeModal = this.modalService.open(TitreModal, {size: 'lg'});
        activeModal.componentInstance.data = this.prepareData();
        activeModal.componentInstance.submit = false;  
        activeModal.componentInstance.isValiderDirect = false;
      }
    }
  }

  submitDemande() {

    this.loading = true;

    if(this.isTableValid()){
   
      if(this.id){
        let demande = new DemandeClientSimple();
        demande.echantillons = this.prepareData();
        demande.id = this.id;
        this.demandesService.submitDemande(demande).subscribe(
          echantillons => {
            this.loading = false;
            this.toaster.pop('success', this.translate.instant('message.settings'), this.translate.instant('message.echantillonSubmitSuccess'));
            this.back();
            this.userService.refresh().subscribe(
              ok => {                
                  if(ok.access_token == null) {
                      localStorage.removeItem('user');
                      this.toaster.pop('warning', this.translate.instant('message.redirection'), this.translate.instant('error.authentication'));
                      this.router.navigate(['/login']);
                  }
               }
            );
          }, 
          error =>
          {
            this.loading = false;
            this.toaster.pop('error', this.translate.instant('message.error'), this.translate.instant('message.echantillonSubmitError'));
            this.userService.refresh().subscribe(
                   ok => {                
                      if(ok.access_token == null) {
                          localStorage.removeItem('user');
                          this.toaster.pop('warning', this.translate.instant('message.redirection'), this.translate.instant('error.authentication'));
                          this.router.navigate(['/login']);
                      }
                   }
              );
          });
      } else {
        
        const activeModal = this.modalService.open(TitreModal, {size: 'lg'});
        activeModal.result.then((result) => {
          this.loading = false;
        }, (reason) => {
          this.loading = false;
        });
        activeModal.componentInstance.data = this.prepareData();
        activeModal.componentInstance.submit = true;   
        activeModal.componentInstance.isValiderDirect = false;
      }

    }
  }

  valideDemande() {

    this.loading = true;

    if(this.isTableValid()){

      let demande = new DemandeClientSimple();
      demande.echantillons = this.prepareData();
      demande.id = this.id;
      this.demandesService.valideDemande(demande).subscribe(
        echantillons => {
          this.loading = false;
          this.toaster.pop('success', this.translate.instant('message.settings'), this.translate.instant('message.echantillonValideSuccess'));
          this.back();
          this.userService.refresh().subscribe(
            ok => {                
                if(ok.access_token == null) {
                    localStorage.removeItem('user');
                    this.toaster.pop('warning', this.translate.instant('message.redirection'), this.translate.instant('error.authentication'));
                    this.router.navigate(['/login']);
                }
             }
          );
        }, 
        error =>
        {
          this.loading = false;
          this.toaster.pop('error', this.translate.instant('message.error'), this.translate.instant('message.echantillonValideError'));
          this.userService.refresh().subscribe(
                 ok => {                
                    if(ok.access_token == null) {
                        localStorage.removeItem('user');
                        this.toaster.pop('warning', this.translate.instant('message.redirection'), this.translate.instant('error.authentication'));
                        this.router.navigate(['/login']);
                    }
                 }
            );
        });

    }

  }

  valideLaboDemande() {

    this.loading = true;

    if(this.isTableValid()){
      const activeModal = this.modalService.open(TitreModal, {size: 'lg'});
      activeModal.result.then((result) => {
          this.loading = false;
        }, (reason) => {
          this.loading = false;
        });
      activeModal.componentInstance.data = this.prepareData();
      activeModal.componentInstance.isValiderDirect = true;      

    }

  }

  refuseDemande() {
    const activeModal = this.modalService.open(CommentaireModal, {size: 'lg'});
    activeModal.componentInstance.data = this.prepareData();
    activeModal.componentInstance.id = this.id;    
  }

  deleteDemande() {
    this.demandesService.deleteDemande(this.id).subscribe(
      echantillons => {
        this.toaster.pop('success', this.translate.instant('message.settings'), this.translate.instant('message.echantillonDeleteSuccess'));
        this.back();
        this.userService.refresh().subscribe(
            ok => {                
                if(ok.access_token == null) {
                    localStorage.removeItem('user');
                    this.toaster.pop('warning', this.translate.instant('message.redirection'), this.translate.instant('error.authentication'));
                    this.router.navigate(['/login']);
                }
             }
          );
      }, 
    error =>
    {
      this.toaster.pop('error', this.translate.instant('message.error'), this.translate.instant('message.echantillonDeleteError'));
      this.userService.refresh().subscribe(
             ok => {                
                if(ok.access_token == null) {
                    localStorage.removeItem('user');
                    this.toaster.pop('warning', this.translate.instant('message.redirection'), this.translate.instant('error.authentication'));
                    this.router.navigate(['/login']);
                }
             }
        );
    });
  }

  prepareData(){

    let data : EchantillonClient[] = [];

    let size = this.hotTableComponent.getHandsontableInstance().getSourceData().length;

    let dataTmp = this.hotTableComponent.getHandsontableInstance().getSourceData();

    for (var i = 0; i < size; i++) {
      if(!this.hotTableComponent.getHandsontableInstance().isEmptyRow(i)){
        data.push({
          id : dataTmp[i].id || dataTmp[i].id == 0 ? dataTmp[i].id :"",
          idDemande : dataTmp[i].idDemande || dataTmp[i].id == 0 ? dataTmp[i].idDemande : "",
          dateReception: dataTmp[i].dateReception ? moment(dataTmp[i].dateReception.trim(), 'L', localStorage.getItem('langage')).locale('fr').format('L') : "",
          visa: dataTmp[i].visa ? dataTmp[i].visa.trim().replace(/\r?\n|\r|;/g, ' ') : "",
          tempReception: dataTmp[i].tempReception ? dataTmp[i].tempReception.trim().replace(/\r?\n|\r|;/g, ' ') : "",
          conforme:dataTmp[i].conforme ? dataTmp[i].conforme.trim().replace(/\r?\n|\r|;/g, ' ') : "",
          dateAnalyse: dataTmp[i].dateAnalyse ? moment(dataTmp[i].dateAnalyse.trim(), 'L', localStorage.getItem('langage')).locale('fr').format('L') : "",
          prefixe: dataTmp[i].prefixe ? dataTmp[i].prefixe.trim().replace(/\r?\n|\r|;/g, ' ') : "",
          centre: dataTmp[i].centre ? dataTmp[i].centre.trim().replace(/\r?\n|\r|;/g, ' ') : "",
          etat: dataTmp[i].etat ? dataTmp[i].etat.trim().replace(/\r?\n|\r|;/g, ' ') : "",
          numEchantillon: dataTmp[i].numEchantillon ? dataTmp[i].numEchantillon.trim().replace(/\r?\n|\r|;/g, ' ') : "",
          designation: dataTmp[i].designation ? dataTmp[i].designation.trim().replace(/\r?\n|\r|;/g, ' ') : "",
          codeCritere: dataTmp[i].codeCritere ? dataTmp[i].codeCritere.trim().replace(/\r?\n|\r|;/g, ' ') : "",
          rechercheSupp1: dataTmp[i].rechercheSupp1 ? dataTmp[i].rechercheSupp1.trim().replace(/\r?\n|\r|;/g, ' ') : "",
          seuil1: dataTmp[i].seuil1 ? (''+dataTmp[i].seuil1).replace(/ /g, '').replace(/\r?\n|\r|;/g, ' ') : "",
          rechercheSupp2: dataTmp[i].rechercheSupp2 ? dataTmp[i].rechercheSupp2.trim().replace(/\r?\n|\r|;/g, ' ') : "",
          seuil2: dataTmp[i].seuil2 ? (''+dataTmp[i].seuil2).replace(/ /g, '').replace(/\r?\n|\r|;/g, ' ') : "",
          rechercheSupp3: dataTmp[i].rechercheSupp3 ? dataTmp[i].rechercheSupp3.trim().replace(/\r?\n|\r|;/g, ' ') : "",
          seuil3: dataTmp[i].seuil3 ? (''+dataTmp[i].seuil3).replace(/ /g, '').replace(/\r?\n|\r|;/g, ' ') : "",
          surface: dataTmp[i].surface ? (''+dataTmp[i].surface).replace(/ /g, '').replace(/\r?\n|\r|;/g, ' ') : "",
          investigation: dataTmp[i].investigation ? dataTmp[i].investigation.trim().replace(/\r?\n|\r|;/g, ' ') : "",
          pathogene: dataTmp[i].pathogene ? dataTmp[i].pathogene.trim().replace(/\r?\n|\r|;/g, ' ') : "",
          dateEnvoi: dataTmp[i].dateEnvoi ? moment(dataTmp[i].dateEnvoi.trim(), 'L', localStorage.getItem('langage')).locale('fr').format('L') : "",
          datePrelevement: dataTmp[i].datePrelevement ? moment(dataTmp[i].datePrelevement.trim(), 'L', localStorage.getItem('langage')).locale('fr').format('L') : "",
          typeAnalyse: dataTmp[i].typeAnalyse ? dataTmp[i].typeAnalyse.trim().replace(/\r?\n|\r|;/g, ' ') : "",
          dateFabrication: dataTmp[i].dateFabrication ? moment(dataTmp[i].dateFabrication.trim(), 'L', localStorage.getItem('langage')).locale('fr').format('L') : "",
          dlc: dataTmp[i].dlc ? moment(dataTmp[i].dlc.trim(), 'L', localStorage.getItem('langage')).locale('fr').format('L') : "",
          dateFabricationTxt: dataTmp[i].dateFabricationTxt ? dataTmp[i].dateFabricationTxt.trim().replace(/\r?\n|\r|;/g, ' ') : "",
          dlcTxt: dataTmp[i].dlcTxt ? dataTmp[i].dlcTxt.trim().replace(/\r?\n|\r|;/g, ' ') : "",
          lieu: dataTmp[i].lieu ? dataTmp[i].lieu.trim().replace(/\r?\n|\r|;/g, ' ') : "",
          service: dataTmp[i].service ? dataTmp[i].service.trim().replace(/\r?\n|\r|;/g, ' ') : "",
          conditionnement: dataTmp[i].conditionnement ? dataTmp[i].conditionnement.trim().replace(/\r?\n|\r|;/g, ' ') : "",
          fournisseur: dataTmp[i].fournisseur ? dataTmp[i].fournisseur.trim().replace(/\r?\n|\r|;/g, ' ') : "",
          compagnie: dataTmp[i].compagnie ? dataTmp[i].compagnie.trim().replace(/\r?\n|\r|;/g, ' ') : "",
          numLot: dataTmp[i].numLot ? dataTmp[i].numLot.trim().replace(/\r?\n|\r|;/g, ' ') : "",
          numCEE: dataTmp[i].numCEE ? dataTmp[i].numCEE.trim().replace(/\r?\n|\r|;/g, ' ') : "",
          codeMenu: dataTmp[i].codeMenu ? dataTmp[i].codeMenu.trim().replace(/\r?\n|\r|;/g, ' ') : "",
          codeElement: dataTmp[i].codeElement ? dataTmp[i].codeElement.trim().replace(/\r?\n|\r|;/g, ' ') : "",
          commentaire: dataTmp[i].commentaire ? dataTmp[i].commentaire.trim().replace(/\r?\n|\r|;/g, ' ') : "",
          cdl: dataTmp[i].cdl ? dataTmp[i].cdl.trim().replace(/\r?\n|\r|;/g, ' ') : "",
          escale: dataTmp[i].escale ? dataTmp[i].escale.trim().replace(/\r?\n|\r|;/g, ' ') : "",
          vol: dataTmp[i].vol ? dataTmp[i].vol.trim().replace(/\r?\n|\r|;/g, ' ') : "",
          tempDateEV: dataTmp[i].tempDateEV ? dataTmp[i].tempDateEV.trim().replace(/\r?\n|\r|;/g, ' ') : "",  
          });
      }
    }
    return data;
  }

	back() {
		this.router.navigate(['/pages/requests']);
	}

  handleDrop(e) {
    e.stopPropagation();
    e.preventDefault();
    var files = e.originalEvent.dataTransfer.files;
    var i, f;
    
    for (i = 0, f = files[i]; i != files.length; ++i) {
      var reader = new FileReader();
      var name = f.name;
      reader.onload = function(e: any) {
        var data = e.target.result;
  
        var workbook = XLSX.read(data, {type: 'array'});

        var worksheet = workbook.Sheets[workbook.SheetNames[0]];

        let nbEchant : number = +Object.keys(worksheet).filter(key => key.substr(0,1) == 'I').pop().substring(1);

        if(!this.data) {
          this.data = [];
        }

        for (var i = 11; i <= nbEchant; i++) {

          let dateReception = "";
          let dateAnalyse = "";
          let dateEnvoi = "";
          let datePrelevement = "";
          let dateFabrication = "";
          let dlc = "";

          if(worksheet['B'+ i]) {
            if(worksheet['B'+ i].t == 'n') {
              dateReception = moment((worksheet['B'+ i].v - (25567 + 2)) * 86400 * 1000).format('L');
            } else {
              dateReception = worksheet['B'+ i].w;
            }
          }

          if(worksheet['F'+ i]) {
            if(worksheet['F'+ i].t == 'n') {
              dateAnalyse = moment((worksheet['F'+ i].v - (25567 + 2)) * 86400 * 1000).format('L');
            } else {
              dateAnalyse = worksheet['F'+ i].w;
            }
          }

          if(worksheet['W'+ i]) {
            if(worksheet['W'+ i].t == 'n') {
              dateEnvoi = moment((worksheet['W'+ i].v - (25567 + 2)) * 86400 * 1000).format('L');
            } else {
              dateEnvoi = worksheet['W'+ i].w;
            }
          }

          if(worksheet['X'+ i]) {
            if(worksheet['X'+ i].t == 'n') {
              datePrelevement = moment((worksheet['X'+ i].v - (25567 + 2)) * 86400 * 1000).format('L');
            } else {
              datePrelevement = worksheet['X'+ i].w;
            }
          }

          if(worksheet['Z'+ i]) {
            if(worksheet['Z'+ i].t == 'n') {
              dateFabrication = moment((worksheet['Z'+ i].v - (25567 + 2)) * 86400 * 1000).format('L');
            } else {
              dateFabrication = worksheet['Z'+ i].w;
            }
          }

          if(worksheet['AA'+ i]) {
            if(worksheet['AA'+ i].t == 'n') {
              dlc = moment((worksheet['AA'+ i].v - (25567 + 2)) * 86400 * 1000).format('L');
            } else {
              dlc = worksheet['AA'+ i].w;
            }
          }

          this.data.push({

            dateReception: this.isLabo ? dateReception : "",
            visa: worksheet['C'+ i] && this.isLabo ? worksheet['C'+ i].w : "",
            tempReception: worksheet['D'+ i] && this.isLabo ? worksheet['D'+ i].w : "",
            conforme: worksheet['E'+ i] && this.isLabo ? worksheet['E'+ i].w : "",
            dateAnalyse: this.isLabo ? dateAnalyse : "",
            prefixe: worksheet['G'+ i] && this.isLabo ? worksheet['G'+ i].w : "",
            centre: worksheet['I'+ i] ? worksheet['I'+ i].w : "",
            etat: worksheet['J'+ i] ? worksheet['J'+ i].w : "",
            numEchantillon: worksheet['K'+ i] ? worksheet['K'+ i].w : "",
            designation: worksheet['L'+ i] ? worksheet['L'+ i].w : "",
            codeCritere: worksheet['M'+ i] ? worksheet['M'+ i].w : "",
            rechercheSupp1: worksheet['N'+ i] ? worksheet['N'+ i].w : "",
            seuil1: worksheet['O'+ i] ? worksheet['O'+ i].w : "",
            rechercheSupp2: worksheet['P'+ i] ? worksheet['P'+ i].w : "",
            seuil2: worksheet['Q'+ i] ? worksheet['Q'+ i].w : "",
            rechercheSupp3: worksheet['R'+ i] ? worksheet['R'+ i].w : "",
            seuil3: worksheet['S'+ i] ? worksheet['S'+ i].w : "",
            surface: worksheet['T'+ i] ? worksheet['T'+ i].w : "",
            investigation: worksheet['U'+ i] ? worksheet['U'+ i].w : "",
            pathogene: worksheet['V'+ i] ? worksheet['V'+ i].w : "",
            dateEnvoi: dateEnvoi,
            datePrelevement: datePrelevement,
            typeAnalyse: worksheet['Y'+ i] ? worksheet['Y'+ i].w : "",
            dateFabrication: dateFabrication,
            dlc: dlc,
            dateFabricationTxt: worksheet['AB'+ i] ? worksheet['AB'+ i].w : "",
            dlcTxt: worksheet['AC'+ i] ? worksheet['AC'+ i].w : "",
            lieu: worksheet['AD'+ i] ? worksheet['AD'+ i].w : "",
            service: worksheet['AE'+ i] ? worksheet['AE'+ i].w : "",
            conditionnement: worksheet['AF'+ i] ? worksheet['AF'+ i].w : "",
            fournisseur: worksheet['AG'+ i] ? worksheet['AG'+ i].w : "",
            compagnie: worksheet['AH'+ i] ? worksheet['AH'+ i].w : "",
            numLot: worksheet['AI'+ i] ? worksheet['AI'+ i].w : "",
            numCEE: worksheet['AJ'+ i] ? worksheet['AJ'+ i].w : "",
            codeMenu: worksheet['AK'+ i] ? worksheet['AK'+ i].w : "",
            codeElement: worksheet['AL'+ i] ? worksheet['AL'+ i].w : "",
            commentaire: worksheet['AM'+ i] ? worksheet['AM'+ i].w : "",
            cdl: worksheet['AN'+ i] ? worksheet['AN'+ i].w : "",
            escale: worksheet['AO'+ i] ? worksheet['AO'+ i].w : "",
            vol: worksheet['AP'+ i] ? worksheet['AP'+ i].w : "",
            tempDateEV: worksheet['AQ'+ i] ? worksheet['AQ'+ i].w : ""           

          });
          
        }

        this.data = cleanDeep(this.data);

        this.hotTableComponent.getHandsontableInstance().render();
        this.hotTableComponent.getHandsontableInstance().getPlugin('autoColumnSize').recalculateAllColumnsWidth()
   
     }.bind(this);
      reader.readAsArrayBuffer(f);
    }
  }

  handleFile(e) {
    var files = e.target.files;
    var i, f;
    
    for (i = 0, f = files[i]; i != files.length; ++i) {
      var reader = new FileReader();
      var name = f.name;
      reader.onload = function(e: any) {

        var data = e.target.result;

        var workbook = XLSX.read(data, {type: 'array'});

        var worksheet = workbook.Sheets[workbook.SheetNames[0]];

        let nbEchant : number = +Object.keys(worksheet).filter(key => key.substr(0,1) == 'I').pop().substring(1);

        if(!this.data) {
          this.data = [];
        }

        for (var i = 11; i <= nbEchant; i++) {

          let dateReception = "";
          let dateAnalyse = "";
          let dateEnvoi = "";
          let datePrelevement = "";
          let dateFabrication = "";
          let dlc = "";

          if(worksheet['B'+ i]) {
            if(worksheet['B'+ i].t == 'n') {
              dateReception = moment((worksheet['B'+ i].v - (25567 + 2)) * 86400 * 1000).format('L');
            } else {
              dateReception = worksheet['B'+ i].w;
            }
          }

          if(worksheet['F'+ i]) {
            if(worksheet['F'+ i].t == 'n') {
              dateAnalyse = moment((worksheet['F'+ i].v - (25567 + 2)) * 86400 * 1000).format('L');
            } else {
              dateAnalyse = worksheet['F'+ i].w;
            }
          }

          if(worksheet['W'+ i]) {
            if(worksheet['W'+ i].t == 'n') {
              dateEnvoi = moment((worksheet['W'+ i].v - (25567 + 2)) * 86400 * 1000).format('L');
            } else {
              dateEnvoi = worksheet['W'+ i].w;
            }
          }

          if(worksheet['X'+ i]) {
            if(worksheet['X'+ i].t == 'n') {
              datePrelevement = moment((worksheet['X'+ i].v - (25567 + 2)) * 86400 * 1000).format('L');
            } else {
              datePrelevement = worksheet['X'+ i].w;
            }
          }

          if(worksheet['Z'+ i]) {
            if(worksheet['Z'+ i].t == 'n') {
              dateFabrication = moment((worksheet['Z'+ i].v - (25567 + 2)) * 86400 * 1000).format('L');
            } else {
              dateFabrication = worksheet['Z'+ i].w;
            }
          }

          if(worksheet['AA'+ i]) {
            if(worksheet['AA'+ i].t == 'n') {
              dlc = moment((worksheet['AA'+ i].v - (25567 + 2)) * 86400 * 1000).format('L');
            } else {
              dlc = worksheet['AA'+ i].w;
            }
          }
         

          this.data.push({

            dateReception: this.isLabo ? dateReception : "",
            visa: worksheet['C'+ i] && this.isLabo ? worksheet['C'+ i].w : "",
            tempReception: worksheet['D'+ i] && this.isLabo ? worksheet['D'+ i].w : "",
            conforme: worksheet['E'+ i] && this.isLabo ? worksheet['E'+ i].w : "",
            dateAnalyse: this.isLabo ? dateAnalyse : "",
            prefixe: worksheet['G'+ i] && this.isLabo ? worksheet['G'+ i].w : "",
            centre: worksheet['I'+ i] ? worksheet['I'+ i].w : "",
            etat: worksheet['J'+ i] ? worksheet['J'+ i].w : "",
            numEchantillon: worksheet['K'+ i] ? worksheet['K'+ i].w : "",
            designation: worksheet['L'+ i] ? worksheet['L'+ i].w : "",
            codeCritere: worksheet['M'+ i] ? worksheet['M'+ i].w : "",
            rechercheSupp1: worksheet['N'+ i] ? worksheet['N'+ i].w : "",
            seuil1: worksheet['O'+ i] ? worksheet['O'+ i].w : "",
            rechercheSupp2: worksheet['P'+ i] ? worksheet['P'+ i].w : "",
            seuil2: worksheet['Q'+ i] ? worksheet['Q'+ i].w : "",
            rechercheSupp3: worksheet['R'+ i] ? worksheet['R'+ i].w : "",
            seuil3: worksheet['S'+ i] ? worksheet['S'+ i].w : "",
            surface: worksheet['T'+ i] ? worksheet['T'+ i].w : "",
            investigation: worksheet['U'+ i] ? worksheet['U'+ i].w : "",
            pathogene: worksheet['V'+ i] ? worksheet['V'+ i].w : "",
            dateEnvoi: dateEnvoi,
            datePrelevement: datePrelevement,
            typeAnalyse: worksheet['Y'+ i] ? worksheet['Y'+ i].w : "",
            dateFabrication: dateFabrication,
            dlc: dlc,
            dateFabricationTxt: worksheet['AB'+ i] ? worksheet['AB'+ i].w : "",
            dlcTxt: worksheet['AC'+ i] ? worksheet['AC'+ i].w : "",
            lieu: worksheet['AD'+ i] ? worksheet['AD'+ i].w : "",
            service: worksheet['AE'+ i] ? worksheet['AE'+ i].w : "",
            conditionnement: worksheet['AF'+ i] ? worksheet['AF'+ i].w : "",
            fournisseur: worksheet['AG'+ i] ? worksheet['AG'+ i].w : "",
            compagnie: worksheet['AH'+ i] ? worksheet['AH'+ i].w : "",
            numLot: worksheet['AI'+ i] ? worksheet['AI'+ i].w : "",
            numCEE: worksheet['AJ'+ i] ? worksheet['AJ'+ i].w : "",
            codeMenu: worksheet['AK'+ i] ? worksheet['AK'+ i].w : "",
            codeElement: worksheet['AL'+ i] ? worksheet['AL'+ i].w : "",
            commentaire: worksheet['AM'+ i] ? worksheet['AM'+ i].w : "",
            cdl: worksheet['AN'+ i] ? worksheet['AN'+ i].w : "",
            escale: worksheet['AO'+ i] ? worksheet['AO'+ i].w : "",
            vol: worksheet['AP'+ i] ? worksheet['AP'+ i].w : "",
            tempDateEV: worksheet['AQ'+ i] ? worksheet['AQ'+ i].w : ""           

          });
          
        }

        this.data = cleanDeep(this.data);

        this.hotTableComponent.getHandsontableInstance().render();
        this.hotTableComponent.getHandsontableInstance().getPlugin('autoColumnSize').recalculateAllColumnsWidth()

      }.bind(this);

      reader.readAsArrayBuffer(f);
    }
  }

  afterChange(changes, source) {

    if (source == 'edit') {

      let cellLabo = localStorage.getItem('user').search('ROLE_LABO') !== -1 ? 6 : 0;
     
      for(let key in changes) {
      
        if(this.tiers && this.tiers.length < 2 && !this.hotTableComponent.getHandsontableInstance().getDataAtCell(changes[key][0], 0+cellLabo)) { 
          this.hotTableComponent.getHandsontableInstance().setDataAtCell(changes[key][0], 0+cellLabo, this.tiers[0].libelle, 'change');   
        }

        if(!(changes[key][1] === 'datePrelevement') && !this.hotTableComponent.getHandsontableInstance().getDataAtCell(changes[key][0], 15+cellLabo)){
          this.hotTableComponent.getHandsontableInstance().setDataAtCell(changes[key][0], 15+cellLabo, moment().locale(localStorage.getItem('langage')).format('L'), 'change');   
        }

        if(!this.hotTableComponent.getHandsontableInstance().getDataAtCell(changes[key][0], 1) && this.isLabo && this.user && this.user.idUser){
            this.hotTableComponent.getHandsontableInstance().setDataAtCell(changes[key][0], 1, this.user.idUser);      
        }

      } 

      this.data = this.hotTableComponent.getHandsontableInstance().getSourceData();

    }

    
  }

  afterCreateRow(index, amount, source) {

    if(this.hotTableComponent.getHandsontableInstance() && source != 'auto' && source != 'Autofill.fill') {

      let cellLabo = localStorage.getItem('user').search('ROLE_LABO') !== -1 ? 6 : 0;
      

      if(this.tiers && this.tiers.length < 2) {
        this.hotTableComponent.getHandsontableInstance().setDataAtCell(index, 0+cellLabo, this.tiers[0].libelle);    
      }    

      this.hotTableComponent.getHandsontableInstance().setDataAtCell(index, 15+cellLabo, moment().locale(localStorage.getItem('langage')).format('L'));        

      if(this.isLabo && this.user && this.user.idUser){
        this.hotTableComponent.getHandsontableInstance().setDataAtCell(index, 1, this.user.idUser);           
      }      
      

      this.data = this.hotTableComponent.getHandsontableInstance().getSourceData();

    }

  }  

  openHelp() {
    const activeModal = this.modalService.open(HelpModal, {size: 'lg', windowClass: 'modal-xl' });
  }

}