import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as AppUtils from 'app/app.utils';
import {User} from 'app/model/User';
import { Observable } from 'rxjs';
import { Procedure } from 'app/model/Procedure';
import { Langue } from 'app/model/Langue';
import { Preference } from 'app/model/Preference';


@Injectable()
export class ProcedureService {

  constructor(
  	private http: HttpClient 
  ) { }

  // récupération des langues
  getLangues(): Observable<Langue[]> {
    return this.http.get<Langue[]>(AppUtils.BACKEND_API_LANGUES, {withCredentials: true});
  }
  // récupération de l'ensemble des procédures
  getAllProcedures(): Observable<Procedure[]> {
    return this.http.get<Procedure[]>(AppUtils.BACKEND_API_PROCEDURES, {withCredentials: true});
  }

  // sauvegarde une procédure éditée
  editProcedure(procedure: Procedure): Observable<Procedure> {
    return this.http.post<Procedure>(AppUtils.BACKEND_API_PROCEDURES_EDIT, procedure, {withCredentials: true});
  }

  // ajoute une langue
  addLangue(langue: Langue): Observable<Langue> {
    return this.http.post<Langue>(AppUtils.BACKEND_API_LANGUE_ADD, langue, {withCredentials: true});
  }

  //supprime une langue
  removeLangue(idLangue: number): Observable<any> {
    return this.http.post<any>(AppUtils.BACKEND_API_LANGUE_RM+'/'+idLangue, {withCredentials: true});
  }

  // récupération de l'ensemble des préférences possibles
  getAllPreferences(): Observable<Preference[]> {
    return this.http.get<Preference[]>(AppUtils.BACKEND_API_ALL_PREFERENCES, {withCredentials: true});
  }

  // récupération de l'ensemble des préférences d'un utilisateur
  getUserPreferences(): Observable<Preference[]> {
    return this.http.get<Preference[]>(AppUtils.BACKEND_API_USER_PREFERENCES, {withCredentials: true});
  }

  // sauvegarde les préférences pour un utilisateur
  savePreferences(preferences): Observable<any>{
    return this.http.post(AppUtils.BACKEND_API_SAVE_PREFERENCES, preferences, {withCredentials: true});
  }

}
