import { Tier } from './Tier';

export class User {

  id: number;
  prenom: string;
  nom: string;
  username: string;
  password: string;
  langue: string;
  admin: boolean;
  labo: boolean;
  idUser: string;
  finValidite: any;
  tiersString: string;
  tiers: Tier[];

  constructor() {   
  }
    
}