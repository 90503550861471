import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DemandesService } from'app/services/demandes.service';
import { EchantillonClient } from 'app/model/EchantillonClient';
import { DemandeClientSimple } from 'app/model/DemandeClientSimple';
import { UserService } from 'app/services/user.service';
import { eLabToasterService } from 'app/services/toaster.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'help-modal',
  templateUrl: './helpModal.component.html'
})

export class HelpModal implements OnInit {

    etats: any[];
    criteres: any[];
    typeAnalyses: any[];
    services: any[];
    conditionnements: any[];
    rechercheSupps: any[];


  constructor(private activeModal: NgbActiveModal, private demandesService: DemandesService, private toaster:eLabToasterService, private userService: UserService, private router: Router, private translate: TranslateService) {
  }

  ngOnInit() {



    this.demandesService.getEtatLangue(localStorage.getItem('langage').toUpperCase()).subscribe(
          etats => { 
            this.etats = etats;
            $(".modal-lg").css("max-width", "900px");
        }, 
        error =>
        {
          this.toaster.pop('error', this.translate.instant('message.error'), this.translate.instant('message.helpModalError'));
          this.userService.refresh().subscribe(
                 ok => {                
                    if(ok.access_token == null) {
                        localStorage.removeItem('user');
                        this.toaster.pop('warning', this.translate.instant('message.redirection'), this.translate.instant('error.authentication'));
                        this.router.navigate(['/login']);
                    }
                 }
            );
        });

  }

  beforeChange($event) {
    
    switch ($event.nextId) {
      case '1':
        this.demandesService.getEtatLangue(localStorage.getItem('langage').toUpperCase()).subscribe(
          etats => { 
            this.etats = etats;
        }, 
        error =>
        {
          this.toaster.pop('error', this.translate.instant('message.error'), this.translate.instant('message.helpModalError'));
          this.userService.refresh().subscribe(
                 ok => {                
                    if(ok.access_token == null) {
                        localStorage.removeItem('user');
                        this.toaster.pop('warning', this.translate.instant('message.redirection'), this.translate.instant('error.authentication'));
                        this.router.navigate(['/login']);
                    }
                 }
            );
        });
        
        break;

      case '2':
        this.demandesService.getCritereLangue(localStorage.getItem('langage').toUpperCase()).subscribe(
          criteres => { 
            this.criteres = criteres;
        }, 
        error =>
        {
          this.toaster.pop('error', this.translate.instant('message.error'), this.translate.instant('message.helpModalError'));
          this.userService.refresh().subscribe(
                 ok => {                
                    if(ok.access_token == null) {
                        localStorage.removeItem('user');
                        this.toaster.pop('warning', this.translate.instant('message.redirection'), this.translate.instant('error.authentication'));
                        this.router.navigate(['/login']);
                    }
                 }
            );
        });
        
        break;

      case '3':
        this.demandesService.getTypeAnalyseLangue(localStorage.getItem('langage').toUpperCase()).subscribe(
          typeAnalyses => { 
            this.typeAnalyses = typeAnalyses;
        }, 
        error =>
        {
          this.toaster.pop('error', this.translate.instant('message.error'), this.translate.instant('message.helpModalError'));
          this.userService.refresh().subscribe(
                 ok => {                
                    if(ok.access_token == null) {
                        localStorage.removeItem('user');
                        this.toaster.pop('warning', this.translate.instant('message.redirection'), this.translate.instant('error.authentication'));
                        this.router.navigate(['/login']);
                    }
                 }
            );
        });

      break;

      case '4':
        this.demandesService.getServicesLangue(localStorage.getItem('langage').toUpperCase()).subscribe(
          services => { 
            this.services = services;
        }, 
        error =>
        {
          this.toaster.pop('error', this.translate.instant('message.error'), this.translate.instant('message.helpModalError'));
          this.userService.refresh().subscribe(
                 ok => {                
                    if(ok.access_token == null) {
                        localStorage.removeItem('user');
                        this.toaster.pop('warning', this.translate.instant('message.redirection'), this.translate.instant('error.authentication'));
                        this.router.navigate(['/login']);
                    }
                 }
            );
        });

      break;

      case '5':
        this.demandesService.getConditionnementLangue(localStorage.getItem('langage').toUpperCase()).subscribe(
          conditionnements => { 
            this.conditionnements = conditionnements;
        }, 
        error =>
        {
          this.toaster.pop('error', this.translate.instant('message.error'), this.translate.instant('message.helpModalError'));
          this.userService.refresh().subscribe(
                 ok => {                
                    if(ok.access_token == null) {
                        localStorage.removeItem('user');
                        this.toaster.pop('warning', this.translate.instant('message.redirection'), this.translate.instant('error.authentication'));
                        this.router.navigate(['/login']);
                    }
                 }
            );
        });

      break;

      case '6':
        this.demandesService.getRechercheSuppLangue(localStorage.getItem('langage').toUpperCase()).subscribe(
          rechercheSupps => { 
            this.rechercheSupps = rechercheSupps;
        }, 
        error =>
        {
          this.toaster.pop('error', this.translate.instant('message.error'), this.translate.instant('message.helpModalError'));
          this.userService.refresh().subscribe(
                 ok => {                
                    if(ok.access_token == null) {
                        localStorage.removeItem('user');
                        this.toaster.pop('warning', this.translate.instant('message.redirection'), this.translate.instant('error.authentication'));
                        this.router.navigate(['/login']);
                    }
                 }
            );
        });

      break;
      
      default:        
        break;
    }

    this.userService.refresh().subscribe(
      ok => {                
          if(ok.access_token == null) {
              localStorage.removeItem('user');
              this.toaster.pop('warning', this.translate.instant('message.redirection'), this.translate.instant('error.authentication'));
              this.router.navigate(['/login']);
          }
       }
    );

  }

  closeModal() {
    this.activeModal.close();
  }

}