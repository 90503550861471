import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DemandesService } from'app/services/demandes.service';
import { EchantillonClient } from 'app/model/EchantillonClient';
import { DemandeClientSimple } from 'app/model/DemandeClientSimple';
import { UserService } from 'app/services/user.service';
import { eLabToasterService } from 'app/services/toaster.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';

@Component({
  selector: 'listesDeroulantes-modal',
  templateUrl: './listesDeroulantes.component.html'
})

export class ListesDeroulantesModal implements OnInit {

  code: string = "";
  type: string;

  constructor(private activeModal: NgbActiveModal, private demandesService: DemandesService, private toaster:eLabToasterService, private userService: UserService, private router: Router, private translate: TranslateService) {
  }

  ngOnInit() {

  }

  add() {

    if(!this.code || this.code == null || this.code.length == 0) {
      this.toaster.pop('error', this.translate.instant('message.listeDeroulante'), this.translate.instant('message.listeDeroulanteVide'));
    } else {

      this.demandesService.addListeDeroulante(this.code, this.type).subscribe(
      listeDeroulante => {
        if((<any>listeDeroulante).id == null) {
          this.toaster.pop('error', this.translate.instant('message.listeDeroulante'), this.translate.instant('message.listeDeroulanteDoublon'));
        } else {
          this.toaster.pop('success', this.translate.instant('message.listeDeroulante'), this.translate.instant('message.listeDeroulanteAddSuccess'));
        }
        
        this.userService.refresh().subscribe(
          ok => {                
              if(ok.access_token == null) {
                  localStorage.removeItem('user');
                  this.toaster.pop('warning', this.translate.instant('message.redirection'), this.translate.instant('error.authentication'));
                  this.router.navigate(['/login']);
              }
           }
        );
        this.activeModal.close('add');
      }, 
      error =>
      {
        this.toaster.pop('error', this.translate.instant('message.error'), this.translate.instant('message.listeDeroulanteAddError'));
        this.userService.refresh().subscribe(
               ok => {                
                  if(ok.access_token == null) {
                      localStorage.removeItem('user');
                      this.toaster.pop('warning', this.translate.instant('message.redirection'), this.translate.instant('error.authentication'));
                      this.router.navigate(['/login']);
                  }
               }
          );
      });  

    }    
   
  }

  closeModal() {
    this.activeModal.close();
  }

}