import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as AppUtils from 'app/app.utils';
import { Observable } from 'rxjs';
import { DemandeClientSimple } from 'app/model/DemandeClientSimple';

@Injectable()
export class DemandesService {

 
  constructor(
  	private http: HttpClient
  ) {
  }

  getEtat() : Observable<any> {
     return this.http.get<any>(AppUtils.BACKEND_API_LIST_ETAT, {withCredentials: true});
  }

  getCritere() {
     return this.http.get<any>(AppUtils.BACKEND_API_LIST_CRITERE, {withCredentials: true});
  }

  getTypeAnalyse() {
     return this.http.get<any>(AppUtils.BACKEND_API_LIST_TYPE_ANALYSE, {withCredentials: true});
  }

  getServices() {
     return this.http.get<any>(AppUtils.BACKEND_API_LIST_SERVICES, {withCredentials: true});
  }

  getConditionnement() {
     return this.http.get<any>(AppUtils.BACKEND_API_LIST_CONDITIONNEMENT, {withCredentials: true});
  }

  getRechercheSupp() {
     return this.http.get<any>(AppUtils.BACKEND_API_LIST_RECHERCHE_SUPP, {withCredentials: true});
  }

  getEtatLangue(langue: string) : Observable<any> {
     return this.http.get<any>(AppUtils.BACKEND_API_LIST_ETAT + '/' + langue, {withCredentials: true});
  }

  getCritereLangue(langue: string) {
     return this.http.get<any>(AppUtils.BACKEND_API_LIST_CRITERE + '/' + langue, {withCredentials: true});
  }

  getTypeAnalyseLangue(langue: string) {
     return this.http.get<any>(AppUtils.BACKEND_API_LIST_TYPE_ANALYSE + '/' + langue, {withCredentials: true});
  }

  getServicesLangue(langue: string) {
     return this.http.get<any>(AppUtils.BACKEND_API_LIST_SERVICES + '/' + langue, {withCredentials: true});
  }

  getConditionnementLangue(langue: string) {
     return this.http.get<any>(AppUtils.BACKEND_API_LIST_CONDITIONNEMENT + '/' + langue, {withCredentials: true});
  }

  getRechercheSuppLangue(langue: string) {
     return this.http.get<any>(AppUtils.BACKEND_API_LIST_RECHERCHE_SUPP + '/' + langue, {withCredentials: true});
  }

  saveValueListeDeroulante(data: any) {
  		return this.http.post(AppUtils.BACKEND_API_SAVE_LIST, data, {withCredentials: true});
  }

  addListeDeroulante(code: string, type: string) {
  	let listeDeroulante = {code: code, type: type};
  	return this.http.post(AppUtils.BACKEND_API_ADD_LIST, listeDeroulante, {withCredentials: true});
  }

  deleteListeDeroulante(code: string, type: string) {
  	let listeDeroulante = {code: code, type: type};
  	return this.http.post(AppUtils.BACKEND_API_DELETE_LIST, listeDeroulante, {withCredentials: true});
  }

  getAllListesDeroulantes() {
  	 return this.http.get<any>(AppUtils.BACKEND_API_ALL_LIST, {withCredentials: true});
  }

  getColonnes(langue:string){
    return this.http.get<any>(AppUtils.BACKEND_API_COLONNES_DEMANDES + '/' + langue, {withCredentials: true});
  }

  getAllStatuts(langue:string){
    return this.http.get<any>(AppUtils.BACKEND_API_STATUT_ALL + '/' + langue, {withCredentials: true});
  }

  getDemandesByStatut(statut:string, page: number, size: number, filter: any){
    return this.http.post<any>(AppUtils.BACKEND_API_DEMANDES_BY_STATUT + '/' + statut + '/' + page + '/' + size , filter, {withCredentials: true});
  }  

  getEchantillonByDemande(demande: string) {
     return this.http.get<any>(AppUtils.BACKEND_API_ECHANTILLONS_BY_DEMANDE + '/' + demande, {withCredentials: true});
  }

  getStatutDemande(id:string){
    return this.http.get(AppUtils.BACKEND_API_STATUT_DEMANDE + '/' + id, {withCredentials: true, responseType: 'text'});
  }

  saveNewDemande(demande: DemandeClientSimple) {
  	return this.http.post(AppUtils.BACKEND_API_DEMANDE_SAVE, demande, {withCredentials: true});
  }

  updateDemande(demande: DemandeClientSimple) {
  	return this.http.post(AppUtils.BACKEND_API_DEMANDE_UPDATE, demande, {withCredentials: true});
  }

  submitDemande(demande: DemandeClientSimple) {
  	return this.http.post(AppUtils.BACKEND_API_DEMANDE_SUBMIT, demande, {withCredentials: true});
  }

  valideDemande(demande: DemandeClientSimple) {
  	return this.http.post(AppUtils.BACKEND_API_DEMANDE_VALIDE, demande, {withCredentials: true});
  }

  valideLaboDemande(demande: DemandeClientSimple) {
    return this.http.post(AppUtils.BACKEND_API_DEMANDE_LABO_VALIDE, demande, {withCredentials: true});
  }

  refuseDemande(demande: DemandeClientSimple) {
  	return this.http.post(AppUtils.BACKEND_API_DEMANDE_REFUSE, demande, {withCredentials: true});
  }

  deleteDemande(demande: string) {
  	return this.http.post(AppUtils.BACKEND_API_DEMANDE_DELETE + '/' + demande, {withCredentials: true});
  }

}
