import { Component } from '@angular/core';
import { Routes } from '@angular/router';

import { BaMenuService } from '../theme';
import { PAGES_MENU } from './pages.menu';
import { VersionService } from 'app/services/version.service';
import * as moment from 'moment';

@Component({
  selector: 'pages',
  template: `
    <ba-sidebar></ba-sidebar>
    <ba-page-top></ba-page-top>
    <div class="al-main">
      <div class="al-content">
        <router-outlet></router-outlet>
      </div>
    </div>
    <footer class="al-footer clearfix">
      <div class="al-footer-main clearfix">
        <div class="al-copy">{{version}} &copy; <a href="http://www.servair.fr" translate>{{'general.societe'}}</a> {{year}}</div>
      </div>
    </footer>
    `
})
export class Pages {

  version: string = "";

  year: number;

  constructor(private _menuService: BaMenuService, private versionService: VersionService) {
    this.versionService.getVersion().subscribe(version => this.version = version);
    this.year = moment().year();
  }

  ngOnInit() {
    this._menuService.updateMenuByRoutes(<Routes>PAGES_MENU);    
  }
}
