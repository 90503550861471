import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DemandesService } from'app/services/demandes.service';
import { EchantillonClient } from 'app/model/EchantillonClient';
import { DemandeClientSimple } from 'app/model/DemandeClientSimple';
import { UserService } from 'app/services/user.service';
import { eLabToasterService } from 'app/services/toaster.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Component({
  selector: 'commentaire-modal',
  templateUrl: './commentaireModal.component.html'
})

export class CommentaireModal implements OnInit {

  id: string;
  data: EchantillonClient[];
  commentaire: string = "";

  constructor(private activeModal: NgbActiveModal, private demandesService: DemandesService, private toaster:eLabToasterService, private userService: UserService, private router: Router,private translate: TranslateService) {
  }

  ngOnInit() {}

  refuser() {
    let demande = new DemandeClientSimple();
    demande.id = this.id;
    demande.commentaire = this.commentaire;
    demande.echantillons = this.data;
    this.demandesService.refuseDemande(demande).subscribe(
      echantillons => {
        this.toaster.pop('success', this.translate.instant('message.settings'), this.translate.instant('message.echantillonRefuseSuccess'));
         this.userService.refresh().subscribe(
            ok => {                
                if(ok.access_token == null) {
                    localStorage.removeItem('user');
                    this.toaster.pop('warning', this.translate.instant('message.redirection'), this.translate.instant('error.authentication'));
                    this.router.navigate(['/login']);
                }
             }
          );
      }, 
    error =>
    {
      this.toaster.pop('error', this.translate.instant('message.error'), this.translate.instant('message.echantillonRefuseError'));
      this.userService.refresh().subscribe(
             ok => {                
                if(ok.access_token == null) {
                    localStorage.removeItem('user');
                    this.toaster.pop('warning', this.translate.instant('message.redirection'), this.translate.instant('error.authentication'));
                    this.router.navigate(['/login']);
                }
             }
        );
    });
    this.router.navigate(['/pages/requests']);
    this.activeModal.close();
  }

  closeModal() {
    this.activeModal.close();
  }

}