import {DomSanitizer} from '@angular/platform-browser';
import {PipeTransform, Pipe, NgModule} from "@angular/core";

// pipe servant à interpréter le html dans les element des pages html
@Pipe({ name: 'safeHtml'})
export class SafeHtmlPipe implements PipeTransform  {
  constructor(private sanitized: DomSanitizer) {}
  transform(value) {
    return this.sanitized.bypassSecurityTrustHtml(value);
  }
}

@NgModule({
  declarations: [SafeHtmlPipe],
  exports: [SafeHtmlPipe],
})
export class SafeHtmlPipeModule { }