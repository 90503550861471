import { Injectable } from '@angular/core';

@Injectable()
export class ValidationService {
   
      // regex pour vérifier une adresse mail
    static emailValidator(control) {
        // RFC 2822 compliant regex
        if (control.value.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
            return null;
        } else {
            return { 'invalidEmailAddress': true };
        }
    }

    // regex pour vérifier la validité d'un mot de passe
    static passwordValidator(control) {
        if (control.value.match(/(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!\\\"#$'()*,\-.\/[\]^_`{}|~:;<>?@°]).{8,}/gm)) {
            return null;
        } else {
            return { 'invalidPassword': true };
        }
    }
}