import { Routes, RouterModule }  from '@angular/router';
import { Pages } from './pages.component';
import { ModuleWithProviders } from '@angular/core';
import { Accueil } from './accueil/accueil.component';
import { LoginGuard } from 'app/guard/';
import { GuestGuard, AdminGuard } from 'app/guard';
import { Settings } from './settings/settings.component';
import { Demandes } from './demandes/demandes.component';
import { EchantillonsComponent } from './echantillons/echantillons.component';
// noinspection TypeScriptValidateTypes

// export function loadChildren(path) { return System.import(path); };

export const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('app/pages/login/login.module').then(m => m.LoginModule),
    canActivate: [GuestGuard]
  },
  {
    path: 'pages',
    component: Pages,
    children: [
      { path: '', redirectTo: 'home', pathMatch: 'full', canActivate: [LoginGuard]},
      { path: 'dashboard', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule), canActivate: [LoginGuard] },
      { path: 'admin', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule), canActivate: [AdminGuard]},
      { path: 'requests', component: Demandes, canActivate: [LoginGuard] },
      { path: 'samples/:id', component: EchantillonsComponent, canActivate: [LoginGuard] },
      { path: 'samples', component: EchantillonsComponent, canActivate: [LoginGuard] },
      { path: 'home', component: Accueil, canActivate: [LoginGuard] },
      { path: 'settings', component: Settings, canActivate: [LoginGuard] }    
    ]
  }
];

export const routing: ModuleWithProviders = RouterModule.forChild(routes);
