
import {map} from 'rxjs/operators';
import { Router } from '@angular/router';

import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable ,  BehaviorSubject } from 'rxjs';
import { User } from '../model/User';
import * as AppUtils from 'app/app.utils';
import { HttpParams } from '@angular/common/http';
import { UserService } from 'app/services/user.service';

// Service gérant l'authentification utilisateur

export class AuthState {
  loggedIn: boolean;
  lastActivity: Date;
}

const SESSION_TIMEOUT = 5000;

@Injectable()
export class AuthService {
  protected authenticatedUser: BehaviorSubject<User> = new BehaviorSubject<User>(new User());
  private authState: AuthState = { loggedIn: false, lastActivity: new Date() };
  private authManager:  BehaviorSubject<AuthState>;
  public activityTimeStamp:  BehaviorSubject<Date> = new BehaviorSubject(new Date());
  public  authChange$:  Observable<AuthState>;

  isAuthenticatedReturn: boolean;

  constructor(
    private http: HttpClient,
    private router: Router,
    private userService: UserService
  ) { }

  // service de connexion
  login(user): Observable<any> {

    const loginHeaders = new HttpHeaders({
      'Accept': 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded'
    });
    let body = `username=${user.username}&password=${user.password}`;

    return this.http.post<any>(AppUtils.BACKEND_API_LOGIN_PATH, body, {headers: loginHeaders, withCredentials: true}).pipe(map(user => {
      if (user) {         
          this.authState.loggedIn = true;              
      }
      return user;
    }))
  }

  // service de déconnexion
  logout() {

    return this.http.get(AppUtils.BACKEND_API_LOGOUT_PATH, { responseType: 'text',  withCredentials: true}).pipe(map(() => {
      this.authState.loggedIn = false;
      localStorage.removeItem('user');
      this.router.navigate(['/login']);
    }));

  }

  // service d'oublie de password
  forgotPassword(user): Observable<any> {
    return this.http.post(AppUtils.BACKEND_API_FORGOT_PWD, user, { withCredentials: true, responseType: 'text'});
  }

}
