import { NgModule } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { TranslateService } from '@ngx-translate/core';
import { LangageService } from 'app/services/langage.service';

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const translationOptions = {
  loader: {
    provide: TranslateLoader,
    useFactory: (createTranslateLoader),
    deps: [HttpClient]
  }
};

@NgModule({
  imports: [TranslateModule.forRoot(translationOptions)],
  exports: [TranslateModule],
  providers: [TranslateService]
})
export class AppTranslationModule {
  constructor(private translate: TranslateService, private langageService: LangageService) {
    this.translate.setDefaultLang(navigator.language.substring(0,2) || 'en');
    this.langageService.getUserLangage().subscribe(
      langue => {
        this.translate.use(langue.toLowerCase());
        this.langageService.setLangage(langue.toLowerCase());
      },
      error => {

      });
  }
}
