import { Component, OnInit } from '@angular/core';
import {ColumnApi, GridApi, GridOptions} from "ag-grid/main";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from 'app/services/user.service';
import { LangageService } from 'app/services/langage.service';
import { DemandeClientSimple } from 'app/model/DemandeClientSimple';
import { TranslateService } from '@ngx-translate/core';
import { eLabToasterService } from 'app/services/toaster.service';
import { DemandesService } from 'app/services/demandes.service';
import { Router } from '@angular/router';
import * as _ from 'underscore';
import * as moment from 'moment';
import {IMyDpOptions} from 'mydatepicker';


@Component({
  selector: 'demandes',
  templateUrl: './demandes.html'
})
export class Demandes implements OnInit{

  onglets: any[];

  ongletSelected: string;

  demandes: DemandeClientSimple[];

  filter: any;

  dateEnvoiRecherche: any;

  pager: any = {};    
  nbPage: number;
  locale: string;

  sortType: string;
  sorTReverse: boolean;

	constructor(private translate: TranslateService, private toaster:eLabToasterService, private router: Router, private userService: UserService, private demandesService: DemandesService) {


	}

  public myDatePickerOptions: IMyDpOptions = {
        editableDateField: false
    };

  ngOnInit() {
      this.locale = localStorage.getItem('langage');
      this.filter = {titre : "", dateEnvoi : "", sortType : 'dateEnvoi', sortReverse: false};

      if(localStorage.getItem('user').search('ROLE_LABO') !== -1){
        this.ongletSelected = 'attente';
      } else {
        this.ongletSelected = 'brouillon';
      }
      
  		this.translate.use(localStorage.getItem('langage'));	
      this.demandesService.getAllStatuts(localStorage.getItem('langage')).subscribe(
        statuts => {
          this.onglets = statuts;
          if(localStorage.getItem('user').search('ROLE_LABO') !== -1){
            this.onglets = this.onglets.filter(e => e.libelle != 'brouillon');
          }
          this.userService.refresh().subscribe(
            ok => {                
                if(ok.access_token == null) {
                    localStorage.removeItem('user');
                    this.toaster.pop('warning', this.translate.instant('message.redirection'), this.translate.instant('error.authentication'));
                    this.router.navigate(['/login']);
                }
             }
          );
        }, 
          error =>
          {
            this.toaster.pop('error', this.translate.instant('message.error'), this.translate.instant('message.demandeError'));
            this.userService.refresh().subscribe(
                   ok => {                
                      if(ok.access_token == null) {
                          localStorage.removeItem('user');
                          this.toaster.pop('warning', this.translate.instant('message.redirection'), this.translate.instant('error.authentication'));
                          this.router.navigate(['/login']);
                      }
                   }
              );
          });

      this.demandesService.getDemandesByStatut(this.ongletSelected, 0, 20, this.filter).subscribe(
        demandes => 
          {
            this.demandes = demandes.content;
            this.pager = this.getPager(demandes.totalPages,demandes.number + 1, demandes.numberOfElements);  
            this.userService.refresh().subscribe(
              ok => {                
                  if(ok.access_token == null) {
                      localStorage.removeItem('user');
                      this.toaster.pop('warning', this.translate.instant('message.redirection'), this.translate.instant('error.authentication'));
                      this.router.navigate(['/login']);
                  }
               }
            );
        }, 
          error =>
          {
            this.toaster.pop('error', this.translate.instant('message.error'), this.translate.instant('message.demandeError'));
            this.userService.refresh().subscribe(
                   ok => {                
                      if(ok.access_token == null) {
                          localStorage.removeItem('user');
                          this.toaster.pop('warning', this.translate.instant('message.redirection'), this.translate.instant('error.authentication'));
                          this.router.navigate(['/login']);
                      }
                   }
              );
          });
	}

	newDemande() {
		this.router.navigate(['/pages/samples']);
	}

  onSelect(demande: DemandeClientSimple): void {
      this.router.navigate(['/pages/samples', demande.id]);
  }

  beforeChange($event) {
    this.ongletSelected = $event.nextId;
    this.filter = {titre : "", dateEnvoi : "", sortType : 'dateEnvoi', sortReverse: false};
    this.demandesService.getDemandesByStatut(this.ongletSelected, 0, 20, this.filter).subscribe(
      demandes => 
        {
          this.demandes = demandes.content;
          this.pager = this.getPager(demandes.totalPages,demandes.number+1, demandes.numberOfElements);  
          this.userService.refresh().subscribe(
            ok => {                
                if(ok.access_token == null) {
                    localStorage.removeItem('user');
                    this.toaster.pop('warning', this.translate.instant('message.redirection'), this.translate.instant('error.authentication'));
                    this.router.navigate(['/login']);
                }
             }
          );
      }, 
          error =>
          {
            this.toaster.pop('error', this.translate.instant('message.error'), this.translate.instant('message.demandeError'));
            this.userService.refresh().subscribe(
                   ok => {                
                      if(ok.access_token == null) {
                          localStorage.removeItem('user');
                          this.toaster.pop('warning', this.translate.instant('message.redirection'), this.translate.instant('error.authentication'));
                          this.router.navigate(['/login']);
                      }
                   }
              );
          });
  }

  getPager(totalPages: number, currentPage: number = 1, pageSize: number = 5) {
        
      let startPage: number, endPage: number;        
      
      if (totalPages <= 5) {
          startPage = 1;
          endPage = totalPages;
      } else {
          if (currentPage <= 3) {
              startPage = 1;
              endPage = 5;
          } else if (currentPage + 1 >= totalPages) {
              startPage = totalPages - 2;
              endPage = totalPages;
          } else {
              startPage = currentPage - 2;
              endPage = currentPage+2;
          }
      }

      // calculate start and end item indexes
      let startIndex = (currentPage - 1) * pageSize;
      let endIndex = startIndex + pageSize - 1;

      // create an array of pages to ng-repeat in the pager control
      let pages = _.range(startPage, endPage + 1);

      // return object with all pager properties required by the view
      return {
          currentPage: currentPage,
          pageSize: pageSize,
          totalPages: totalPages,
          startPage: startPage,
          endPage: endPage,
          startIndex: startIndex,
          endIndex: endIndex,
          pages: pages
      };

  }

  setPage(page: number) {
    if (page < 1 || page > this.pager.totalPages) {
        return;
    }
   
    this.demandesService.getDemandesByStatut(this.ongletSelected, page-1, 20, this.filter).subscribe(
        demandes => 
          {
            this.demandes = demandes.content;
            this.pager = this.getPager(demandes.totalPages,demandes.number + 1, demandes.numberOfElements);  
            this.userService.refresh().subscribe(
              ok => {                
                  if(ok.access_token == null) {
                      localStorage.removeItem('user');
                      this.toaster.pop('warning', this.translate.instant('message.redirection'), this.translate.instant('error.authentication'));
                      this.router.navigate(['/login']);
                  }
               }
            );
        }, 
          error =>
          {
            this.toaster.pop('error', this.translate.instant('message.error'), this.translate.instant('message.demandeError'));
            this.userService.refresh().subscribe(
                   ok => {                
                      if(ok.access_token == null) {
                          localStorage.removeItem('user');
                          this.toaster.pop('warning', this.translate.instant('message.redirection'), this.translate.instant('error.authentication'));
                          this.router.navigate(['/login']);
                      }
                   }
              );
          });
  }

  rechercher() {

    if(this.dateEnvoiRecherche != undefined && moment.unix(this.dateEnvoiRecherche.epoc).isValid()) {
      this.filter.dateEnvoi = moment.unix(this.dateEnvoiRecherche.epoc).locale('fr').format('L');
    } else {
      this.filter.dateEnvoi = "";
    }

    this.toaster.pop('wait', this.translate.instant('message.search'), this.translate.instant('message.searchProgress'));

    this.demandesService.getDemandesByStatut(this.ongletSelected, 0, 20, this.filter).subscribe(
      demandes => 
        {
          this.demandes = demandes.content;
          this.pager = this.getPager(demandes.totalPages,demandes.number, demandes.numberOfElements);  
          this.userService.refresh().subscribe(
            ok => {                
                if(ok.access_token == null) {
                    localStorage.removeItem('user');
                    this.toaster.pop('warning', this.translate.instant('message.redirection'), this.translate.instant('error.authentication'));
                    this.router.navigate(['/login']);
                }
             }
          );
      }, 
          error =>
          {
            this.toaster.pop('error', this.translate.instant('message.error'), this.translate.instant('message.demandeError'));
            this.userService.refresh().subscribe(
                   ok => {                
                      if(ok.access_token == null) {
                          localStorage.removeItem('user');
                          this.toaster.pop('warning', this.translate.instant('message.redirection'), this.translate.instant('error.authentication'));
                          this.router.navigate(['/login']);
                      }
                   }
              );
          });

  }

}
