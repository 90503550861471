import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DemandesService } from'app/services/demandes.service';
import { EchantillonClient } from 'app/model/EchantillonClient';
import { DemandeClientSimple } from 'app/model/DemandeClientSimple';
import { UserService } from 'app/services/user.service';
import { eLabToasterService } from 'app/services/toaster.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';

@Component({
  selector: 'titre-modal',
  templateUrl: './titreModal.component.html'
})

export class TitreModal implements OnInit {

  id: string;
  data: EchantillonClient[];
  titre: string = "";
  numCommande: string = "";
  submit: boolean;
  isValiderDirect: boolean;

  loading: boolean = false;

  @ViewChild('inputTitre', { static: true }) inputTitre: ElementRef;

  constructor(private activeModal: NgbActiveModal, private demandesService: DemandesService, private toaster:eLabToasterService, private userService: UserService, private router: Router, private translate: TranslateService) {
  }

  ngOnInit() {

    this.titre = moment().locale(localStorage.getItem('langage')).format('L');
    <HTMLInputElement>this.inputTitre.nativeElement.select();

  }

  sauvegarder() {

    this.loading = true;

    if(this.isValiderDirect) {

      let demande = new DemandeClientSimple();
      demande.titre = this.titre;
      demande.numCommande = this.numCommande;
      demande.echantillons = this.data;
      this.demandesService.valideLaboDemande(demande).subscribe(
        echantillons => {
          this.loading = false;
          this.activeModal.close();
          this.toaster.pop('success', this.translate.instant('message.settings'), this.translate.instant('message.echantillonValideSuccess'));
          this.router.navigate(['/pages/requests']);
          this.userService.refresh().subscribe(
            ok => {                
                if(ok.access_token == null) {
                    localStorage.removeItem('user');
                    this.toaster.pop('warning', this.translate.instant('message.redirection'), this.translate.instant('error.authentication'));
                    this.router.navigate(['/login']);
                }
             }
          );
        }, 
        error =>
        {
          this.loading = false;
          this.activeModal.close();
          this.toaster.pop('error', this.translate.instant('message.error'), this.translate.instant('message.echantillonValideError'));
          this.userService.refresh().subscribe(
                 ok => {                
                    if(ok.access_token == null) {
                        localStorage.removeItem('user');
                        this.toaster.pop('warning', this.translate.instant('message.redirection'), this.translate.instant('error.authentication'));
                        this.router.navigate(['/login']);
                    }
                 }
            );
        });

    } else {

      let demande = new DemandeClientSimple();
      demande.titre = this.titre;
      demande.echantillons = this.data;
      if(this.submit) {
        this.demandesService.submitDemande(demande).subscribe(
          echantillons => {
            this.loading = false;
            this.activeModal.close();
            this.toaster.pop('success', this.translate.instant('message.settings'), this.translate.instant('message.echantillonSubmitSuccess'));
            this.router.navigate(['/pages/requests']);
          }, 
          error =>
          {
            this.loading = false;
            this.activeModal.close();
            this.toaster.pop('error', this.translate.instant('message.error'), this.translate.instant('message.echantillonSubmitError'));
            this.userService.refresh().subscribe(
                   ok => {                
                      if(ok.access_token == null) {
                          localStorage.removeItem('user');
                          this.toaster.pop('warning', this.translate.instant('message.redirection'), this.translate.instant('error.authentication'));
                          this.router.navigate(['/login']);
                      }
                   }
              );
          });
      } else {
        this.demandesService.saveNewDemande(demande).subscribe(
          echantillons => {
            this.loading = false;
            this.activeModal.close();
            this.toaster.pop('success', this.translate.instant('message.settings'), this.translate.instant('message.echantillonUpdateSuccess'));
            this.router.navigate(['/pages/requests']);
          }, 
          error =>
          {
            this.loading = false;
            this.activeModal.close();
            this.toaster.pop('error', this.translate.instant('message.error'), this.translate.instant('message.echantillonUpdateError'));
            this.userService.refresh().subscribe(
                   ok => {                
                      if(ok.access_token == null) {
                          localStorage.removeItem('user');
                          this.toaster.pop('warning', this.translate.instant('message.redirection'), this.translate.instant('error.authentication'));
                          this.router.navigate(['/login']);
                      }
                   }
              );
          });
      }

    }

    

    this.userService.refresh().subscribe(
      ok => {                
          if(ok.access_token == null) {
              localStorage.removeItem('user');
              this.toaster.pop('warning', this.translate.instant('message.redirection'), this.translate.instant('error.authentication'));
              this.router.navigate(['/login']);
          }
       }
    );    
    
  }

  closeModal() {
    this.activeModal.close();
  }

}