import {PipeTransform, Pipe, NgModule} from "@angular/core";

@Pipe({
    name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform(items: any, filter: any): any {

    if (filter && Array.isArray(items)) {
      let filterKeys = Object.keys(filter);
     
        return items.filter(item => {
          return filterKeys.some((keyName) => {
            if(filter[keyName]){
              let filterTmp = filter[keyName].replace(/[\?\*\[\]\(\)\{\}\\\^\$]/g, '\\$&');
              return new RegExp(filterTmp, 'gi').test(item[keyName]) || filter[keyName] === "";
            } else {
              return new RegExp(filter[keyName], 'gi').test(item[keyName]) || filter[keyName] === "";  
            }
            
          });
        });
      
    } else {
      return items;
    }
  }

}

@NgModule({
  declarations: [FilterPipe],
  exports: [FilterPipe],
})
export class FilterPipeModule { }