import { environment } from '../environments/environment';

// Headers HTTP
export const J_SESSION_ID = 'JSESSIONID';

// Common http root api
export const BACKEND_API_PATH = 'api';

// Login services
export const BACKEND_API_LOGIN_PATH: string = BACKEND_API_PATH + '/login';
export const BACKEND_API_LOGOUT_PATH: string = BACKEND_API_PATH + '/logout';
export const BACKEND_API_INSCRIPTION: string = BACKEND_API_PATH + '/inscription';
export const BACKEND_API_CHANGE_MDP: string = BACKEND_API_PATH + '/changePwd';
export const BACKEND_API_FORGOT_PWD: string = BACKEND_API_PATH + '/forgot';
export const BACKEND_API_IS_AUTHENTICATED: string = BACKEND_API_PATH + '/isAuthenticated';

// User services
export const BACKEND_API_WHOAMI: string = BACKEND_API_PATH + '/whoami';
export const BACKEND_API_REFRESH: string = BACKEND_API_PATH + '/refresh';
export const BACKEND_API_ALL_USERS: string = BACKEND_API_PATH + '/allUsers';
export const BACKEND_API_ALL_TIERS: string = BACKEND_API_PATH + '/allTiers';
export const BACKEND_API_SAVE_USER: string = BACKEND_API_PATH + '/saveUser';
export const BACKEND_API_DELETE_USER: string = BACKEND_API_PATH + '/deleteUser';
export const BACKEND_API_LANG: string = BACKEND_API_PATH + '/lang';
export const BACKEND_API_SAVE_LANGUE: string = BACKEND_API_PATH + '/saveLangue';
export const BACKEND_API_ALL_LANGUE: string = BACKEND_API_PATH + '/allLangue';
export const BACKEND_API_BLOC_HTML: string = BACKEND_API_PATH + '/blocHtml';
export const BACKEND_API_ALL_BLOC_HTML: string = BACKEND_API_PATH + '/allBlocHtml';
export const BACKEND_API_NUMBER_BLOC: string = BACKEND_API_PATH + '/numberBloc';
export const BACKEND_API_SAVE_BLOC: string = BACKEND_API_PATH + '/saveBloc';

// Echantillon services
export const BACKEND_API_ECHANTILLON_PATH: string = BACKEND_API_PATH + '/echantillon/';
export const BACKEND_API_TRADUCTION_PATH: string = BACKEND_API_PATH + '/traduction/';

// Procedure services
export const BACKEND_API_LANGUES: string = BACKEND_API_PATH + '/langues';
export const BACKEND_API_PROCEDURES: string = BACKEND_API_PATH + '/procedures';
export const BACKEND_API_PROCEDURES_EDIT: string = BACKEND_API_PROCEDURES + '/edit';
export const BACKEND_API_ALL_PREFERENCES: string = BACKEND_API_PATH + '/allPreferences';
export const BACKEND_API_USER_PREFERENCES: string = BACKEND_API_PATH + '/userPreferences';
export const BACKEND_API_SAVE_PREFERENCES: string = BACKEND_API_PATH + '/savePreferences';

// Langue services
export const BACKEND_API_LANGUE_ADD: string = BACKEND_API_LANGUES + '/add';
export const BACKEND_API_LANGUE_RM: string = BACKEND_API_LANGUES + '/remove';

// Version
export const BACKEND_API_VERSION: string = BACKEND_API_PATH + '/version';

// Listes déroulantes
export const BACKEND_API_LIST_ETAT: string = BACKEND_API_PATH + '/list/etat';
export const BACKEND_API_LIST_CRITERE: string = BACKEND_API_PATH + '/list/critere';
export const BACKEND_API_LIST_TYPE_ANALYSE: string = BACKEND_API_PATH + '/list/typeAnalyse';
export const BACKEND_API_LIST_SERVICES: string = BACKEND_API_PATH + '/list/services';
export const BACKEND_API_LIST_CONDITIONNEMENT: string = BACKEND_API_PATH + '/list/conditionnement';
export const BACKEND_API_LIST_RECHERCHE_SUPP: string = BACKEND_API_PATH + '/list/rechercheSupp';
export const BACKEND_API_ALL_LIST: string = BACKEND_API_PATH + '/list/all';
export const BACKEND_API_SAVE_LIST: string = BACKEND_API_PATH + '/list/save';
export const BACKEND_API_ADD_LIST: string = BACKEND_API_PATH + '/list/add';
export const BACKEND_API_DELETE_LIST: string = BACKEND_API_PATH + '/list/delete';

// Colonnes Demandes
export const BACKEND_API_COLONNES_DEMANDES: string = BACKEND_API_PATH + '/traductionEchantillon/colonnes';

// Statut des demandes
export const BACKEND_API_STATUT_ALL: string = BACKEND_API_PATH + '/statut/all';

// Liste des tiers liés à un utilisateur
export const BACKEND_API_TIERS_BY_USER: string = BACKEND_API_PATH + '/user/tier';

// Liste des demandes par statut
export const BACKEND_API_DEMANDES_BY_STATUT: string = BACKEND_API_PATH + '/demandes/statut';

// Liste des echantillons par demande
export const BACKEND_API_ECHANTILLONS_BY_DEMANDE: string = BACKEND_API_PATH + '/demande';

// Recherche des demandes
export const BACKEND_API_SEARCH_DEMANDES: string = BACKEND_API_PATH + '/demandes/search';

export const BACKEND_API_STATUT_DEMANDE: string = BACKEND_API_PATH + '/statut/demande';

export const BACKEND_API_DEMANDE_SAVE: string = BACKEND_API_PATH + '/demande/save';
export const BACKEND_API_DEMANDE_SUBMIT: string = BACKEND_API_PATH + '/demande/submit';
export const BACKEND_API_DEMANDE_UPDATE: string = BACKEND_API_PATH + '/demande/update';
export const BACKEND_API_DEMANDE_VALIDE: string = BACKEND_API_PATH + '/demande/valide';
export const BACKEND_API_DEMANDE_LABO_VALIDE: string = BACKEND_API_PATH + '/demande/labo/valide';
export const BACKEND_API_DEMANDE_REFUSE: string = BACKEND_API_PATH + '/demande/refuse';
export const BACKEND_API_DEMANDE_DELETE: string = BACKEND_API_PATH + '/demande/delete';