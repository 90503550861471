import {PipeTransform, Pipe, NgModule} from "@angular/core";
import * as moment from 'moment';

@Pipe({
    name: 'dateFormat'
})
export class DatePipe implements PipeTransform {

  transform(value): any {

   return moment(value).locale(localStorage.getItem('langage')).format('L');
  }

}

@NgModule({
  declarations: [DatePipe],
  exports: [DatePipe],
})
export class DatePipeModule { }