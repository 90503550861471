
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as AppUtils from 'app/app.utils';
import { User } from 'app/model/User';
import { Observable } from 'rxjs';
import { Tier } from 'app/model/Tier';
import { BlocHtml } from 'app/model/BlocHtml';

@Injectable()
export class UserService {

  currentUser;

  constructor(
  	private http: HttpClient 
  ) { }
  // stocke l'utilisateur et sa langue dans le localstorage
  getMyInfo() {
    return this.http.get<User>(AppUtils.BACKEND_API_WHOAMI, {withCredentials: true}).pipe(map(user => {
                                                                                    localStorage.setItem('user', JSON.stringify(user)); 
                                                                                    localStorage.setItem('langage', user.langue.toLowerCase());
                                                                                  }));
  }
  // rafraichi le token et sert aussi pour vérifier si l'utilisateur est bien connecté
  refresh(): Observable<any> {
    return this.http.get(AppUtils.BACKEND_API_REFRESH, {withCredentials: true});
  }

  // récupère l'ensembles des utilisateurs
  getAllUsers(): Observable<User[]> {
    return this.http.get<User[]>(AppUtils.BACKEND_API_ALL_USERS, {withCredentials: true});
  }

  // récupère l'ensemble des tiers
  getAllTiers(): Observable<Tier[]> {
    return this.http.get<Tier[]>(AppUtils.BACKEND_API_ALL_TIERS, {withCredentials: true});
  }

  // sauvegarde un utilisateur
  saveUser(user: User): Observable<User> {  	
  	return this.http.post<User>(AppUtils.BACKEND_API_SAVE_USER, user);
  }

  // supprime un utilisateur
  deleteUser(user: string): Observable<any>{  	
  	return this.http.post(AppUtils.BACKEND_API_DELETE_USER, user, { withCredentials: true, responseType: 'text'});
  }

  // ajoute un utilisateur
  inscription(user: User): Observable<User> {
    return this.http.post<User>(AppUtils.BACKEND_API_INSCRIPTION, user);
  }

  // change le mot de passe de l'utilisateur
  changePassword(mdp: any): Observable<any> {
    return this.http.post(AppUtils.BACKEND_API_CHANGE_MDP, mdp, { withCredentials: true});
  }

  // récupère le nombre 
  getNumberBloc(): Observable<number> {
    return this.http.get<number>(AppUtils.BACKEND_API_NUMBER_BLOC, { withCredentials: true});
  }

  // récupère le bloc sélectionné
  getBloc(numero: number, langue: string): Observable<BlocHtml> {
    let br = {numero: numero, langue: langue}
    return this.http.post<BlocHtml>(AppUtils.BACKEND_API_BLOC_HTML, br, { withCredentials: true});
  }

  // savegarde la modification d'un bloc html
  saveBloc(bloc: BlocHtml): Observable<BlocHtml> {
    return this.http.post<BlocHtml>(AppUtils.BACKEND_API_SAVE_BLOC, bloc, { withCredentials: true});
  }

  // récupère les blocs de la page d'accueil
  getAllBloc(langue: string): Observable<BlocHtml[]> {
    return this.http.post<BlocHtml[]>(AppUtils.BACKEND_API_ALL_BLOC_HTML, langue);
  }

   getTiersByUser(): Observable<Tier[]> {
    return this.http.get<Tier[]>(AppUtils.BACKEND_API_TIERS_BY_USER, {withCredentials: true});
  }

}
