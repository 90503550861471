export const PAGES_MENU = [
  {
    path: 'pages',
    children: [
      {
        path: 'home',
        data: {
          menu: {
            title: 'general.home',
            icon: 'ion-android-home',
            selected: false,
            expanded: false,
            order: 0
          }
        }
      },
      {
        path: 'requests',
        data: {
          menu: {
            title: 'general.menu.requests',
            icon: 'ion-android-folder',
            selected: false,
            expanded: false,
            order: 0
          }
        }
      },
      {
        path: 'dashboard',
        data: {
          menu: {
            title: 'general.menu.dashboard',
            icon: 'fa fa-flask',
            selected: false,
            expanded: false,
            order: 0
          }
        }
      },      
      {
        path: 'admin',
        data: {
          menu: {
            title: 'general.menu.admin',
            icon: 'ion-android-laptop',
            selected: false,
            expanded: false,
            order: 300,
          }
        },
        children: [          
          {
            path: 'utilisateurs',
            data: {
              menu: {
                title: 'general.menu.users',
              }
            },
             children: [          
              {
                path: 'create',
                data: {
                  menu: {
                    title: 'general.menu.create',
                  }
                }
              },
              {
                path: 'edit',
                data: {
                  menu: {
                    title: 'general.menu.edit',
                  }
                }
              }
            ]
          },
          {
            path: 'procedures',
            data: {
              menu: {
                title: 'general.menu.procedure',
              }
            }
          },
          {
            path: 'listesDeroulantes',
            data: {
              menu: {
                title: 'general.menu.listesDeroulantes',
              }
            }
          },
          {
            path: 'languages',
            data: {
              menu: {
                title: 'general.menu.langage',
              }
            }
          },
          {
            path: 'editHome',
            data: {
              menu: {
                title: 'general.menu.home',
              }
            }
          }              
        ]
      }    
    ]
  }
];
