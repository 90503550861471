import { Injectable } from '@angular/core';
import {ToastyService, ToastyConfig, ToastOptions, ToastData} from 'ng2-toasty';

@Injectable()
export class eLabToasterService {

  constructor(private toaster: ToastyService) { }

  // fonction servant à faire apparaitre un toaster. plusieurs type possibles (info, success, wait, error, warning, default)
  pop(type, title = null, body = null) {

    let timeoutTmp = type == 'wait' ? 1000 : 5000;

    var toastOptions:ToastOptions = {
          title: title,
          msg: body,
          showClose: true,
          timeout: timeoutTmp,
          theme: 'boostrap',
          onAdd: (toast:ToastData) => {
          },
          onRemove: function(toast:ToastData) {
          }
      };  

      switch(type){

        case 'info': {
          this.toaster.info(toastOptions);   
          break;      
        }
        case 'success': {
          this.toaster.success(toastOptions);
          break; 
        }
        case 'wait': {
          this.toaster.wait(toastOptions);
          break; 
        }
        case 'error': {
          this.toaster.error(toastOptions);
          break; 
        }
        case 'warning': {
          this.toaster.warning(toastOptions);
          break; 
        }
        case 'default': {
          this.toaster.default(toastOptions);
          break; 
        }
        
      } 
    
  }

}