import { NgModule }      from '@angular/core';
import { CommonModule }  from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { routing }       from './pages.routing';
import { NgaModule } from '../theme/nga.module';
import { AppTranslationModule } from '../app.translation.module';
import { AngularDualListBoxModule } from 'angular-dual-listbox';

import { UserService } from 'app/services/user.service';
import { AuthService } from 'app/services/auth.service';
import { ProcedureService } from 'app/services/procedure.service';

import { Pages } from './pages.component';
import { Accueil } from './accueil/accueil.component';
import { Settings } from './settings/settings.component';
import {SafeHtmlPipeModule} from "app/pipe/pipeHtml.module";
import { VersionService } from 'app/services/version.service';

import { Demandes } from './demandes/demandes.component';
import { EchantillonsComponent } from './echantillons/echantillons.component';

import { DemandesService } from'app/services/demandes.service';
import { HotTableModule } from 'ng2-handsontable';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import {DatePipeModule} from "app/pipe/pipeDate.module";
import {FilterPipeModule} from "app/pipe/pipeFilter.module";

import { MyDatePickerModule } from 'mydatepicker';
import { CommentaireModal } from'app/modal/commentaire/commentaireModal.component';
import { TitreModal } from'app/modal/titre/titreModal.component';
import { HelpModal } from'app/modal/help/helpModal.component';
import { ListesDeroulantesModal } from'app/modal/listesDeroulantes/listesDeroulantes.component';


@NgModule({
  imports: [CommonModule,
  			AppTranslationModule, 
  			NgaModule, 
        NgbModule,
  			routing, 
  			FormsModule, 
  			ReactiveFormsModule,
  			AngularDualListBoxModule,
        SafeHtmlPipeModule,
        HotTableModule,
        DatePipeModule,
        FilterPipeModule,
        MyDatePickerModule
  			],
  declarations: [Pages, Accueil, Settings, Demandes, EchantillonsComponent, CommentaireModal, TitreModal, HelpModal, ListesDeroulantesModal],
  providers: [AuthService, UserService, ProcedureService, DemandesService, VersionService],
  entryComponents: [CommentaireModal, TitreModal, HelpModal, ListesDeroulantesModal],

})
export class PagesModule {
}
